import { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import API from '../../API';
import Brand from '../widgets/brands/Brand';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ItemEditor from '../panels/ItemEditor';
import BreadCrumbs from '../widgets/BreadCrumbs';
import IdentityContext from '../auth/IdentityContext';
import SaveIcon from '@mui/icons-material/Save';
import { IconButton } from '@mui/material';

const ItemEditorPage = () => {
  const {identity, setIdentity} = useContext(IdentityContext);
  let { itemId } = useParams<{itemId: string}>();
  const [item, setItem] = useState<IItem>({
      id: '',
      name: '',
      description: '',
      imageId: '',
      categoryId: '',
      supplierUrls: [],
      homeUrls: [],
      externalId: {},
      inventory: {}
  });

  const [onSavePage, setOnSavePage] = React.useState(() => () => {});
  const [reloadPage, setReloadPage] = React.useState<boolean>(true);
  const [selectedVariation, setSelectedVariation] = React.useState<IItem>(item);
  const [variants, setVariants] = useState<IItem[]>([selectedVariation]);

  const [itemCount, setItemCount] = React.useState<number>(1);

  const history = useHistory();
  const location = useLocation();

  /**
   * From materialUI "Integration with 3rd party input libraries":
   *   https://material-ui.com/components/text-fields/#integration-with-3rd-party-input-libraries
   */
  const handleChange = (value: number) => {
    setItemCount(value);
  };
 
  useEffect(() => {
    const fetchItem = async () => {
      try {
        const item = await API.getItemById(itemId, identity?.accessToken);
        setItem(item);
        setSelectedVariation(item);

        if (item.group?.enabled && !item.group.variantName) {
          // Download the information about the variants as well.
          setVariants(await API.getVariantsById(itemId));
        }
        else {
          // This is its own variant
          setVariants([item]);
        }
      }
      catch (err: any) {
        setItem({
          id: 'ERROR',
          name: err.message,
          categoryId: '',
          supplierUrls: [],
          homeUrls: [],
          externalId: {},
          inventory: {}
        });
        console.error(err);
      }
    }
    if (itemId) {
      fetchItem();
    }
  }, [itemId, reloadPage]);

  if (!itemId) {
    window.document.body.style.overflow = 'auto';
    return (
      <>
      </>
    )
  }

  window.document.body.style.overflow = 'hidden';

  if (!item.id) {
    return (
      <div id="id-main">
        Loading item: {itemId}
      </div>
    )
  }

  if ("ERROR" === item.id) {
    return (
      <div id="id-main">
        Error loading item: {item.name}
      </div>
    )
  }

  const goBack = () => {
      if (location.state && (location.state as any).from) {
        history.goBack();
      }
      else {
        console.log(`history.push(/)`);
        history.push('/');
        history.go(0);
      }
  }

  const doOnSavePage = () => {
    onSavePage();
  }

  return (
    <>
      <div className="overlay" onClick={goBack}></div>
      <div id="id-main">
        <div className="header-bar">
          <BreadCrumbs item={item} variant={selectedVariation}/>
          <div className="spacer"></div>
          <IconButton
            className="save-icon"
            aria-label="save"
            onClick={doOnSavePage}
            size="large">
            <SaveIcon />
          </IconButton>
          <IconButton
            className="close-icon"
            aria-label="back"
            onClick={goBack}
            size="large">
            <CloseIcon />
          </IconButton>
        </div>
        <div className="dialog-body">
          <div className='hlayout'>
            <Brand name={item.brand ? item.brand : ''} />
          </div>
          <div>{item.name}</div>
          <div className="id-content">
            <ItemEditor item={item} reloadPage={reloadPage} setReloadPage={setReloadPage} setOnSavePage={setOnSavePage}/>
          </div>
        </div>
      </div>
    </>
  );
}

export default ItemEditorPage