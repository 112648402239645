import React, { useEffect, useState, useContext } from 'react';
import IdentityContext from '../auth/IdentityContext';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card, FormControl, InputLabel, MenuItem, NativeSelect, Select, SelectChangeEvent } from '@mui/material';

import Price from '../widgets/shop/Price';
import API from '../../API';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import IfAuthorized from '../conditions/IfAuthorized';

const AnalysisPage = () => {
  const {identity, setIdentity} = useContext(IdentityContext);
  const [yearlySummary, setYearlySummary] = useState<any[]>([]);
  const [thisYearMonthlySummary, setThisYearMonthlySummary] = useState<any[]>([]);
  const [lastYearMonthlySummary, setLastYearMonthlySummary] = useState<any[]>([]);
  const [weeklySummary, setWeeklySummary] = useState<any>({});
  const [location, setLocation] = useState<string>("All");

  const now = new Date();
  const thisYear = now.getFullYear();
  const lastYear = thisYear - 1;

  const FIRST_YEAR = 2019;

  useEffect(() => {
    const doFetchTransactions = async() => {
      const myTransactions = await API.getTransactionsHourlyRollup(location, new Date(), new Date(), identity?.accessToken);
      if (myTransactions) {
        setYearlySummary(myTransactions);
      }

  //     const newSummary: Record<number, any[]> = {};
  //     for (let year = FIRST_YEAR; year <= thisYear; year++) {
  //       const lastYearWeeklyTransactions = await API.getWeeklyTransactionSummary(location, year, identity?.accessToken);
  //       if (lastYearWeeklyTransactions) {
  //         newSummary[year] = lastYearWeeklyTransactions;
  //       }  
  //     }
  //     setWeeklySummary(newSummary);

  //     const thisYearTransactions = await API.getMonthlyTransactionSummary(location, thisYear, identity?.accessToken);
  //     if (thisYearTransactions) {
  //       setThisYearMonthlySummary(thisYearTransactions);
  //     }
  //     const lastYearTransactions = await API.getMonthlyTransactionSummary(location, lastYear, identity?.accessToken);
  //     if (lastYearTransactions) {
  //       setLastYearMonthlySummary(lastYearTransactions);
  //     }
    }

    doFetchTransactions();
  }, [location]);

  // const handleLocationChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   setLocation(event.target.value as string);
  // }

  // const yearTotalMap: Record<string, number> = {};
  // const yearSubtotalMap: Record<string, number> = {};

  // const monthTotalMap: Record<number, Record<number, number>> = {};
  // const monthSubtotalMap: Record<number, Record<number, number>> = {};

  // for (let i = 0; i < yearlySummary.length; i++) {
  //   const transaction = yearlySummary[i];
  //   const subtotal = transaction.subtotal;
  //   const total = transaction.total;
  //   const year = transaction._id;

  //   yearTotalMap[year] = yearTotalMap[year] ? yearTotalMap[year] + total : total;
  //   yearSubtotalMap[year] = yearSubtotalMap[year] ? yearSubtotalMap[year] + subtotal : subtotal;
  // }

  // for (let i = 1; i <= 12; i++) {
  //   monthTotalMap[i] = {};
  //   monthSubtotalMap[i] = {};
  // }

  // for (let i = 0; i < 12; i++) {
  //   {
  //     const transaction = thisYearMonthlySummary[i];
  //     if (transaction) {
  //       const month = +(transaction._id);
  //       const total = transaction.total;
  //       const subtotal = transaction.subtotal;

  //       monthTotalMap[month][thisYear] = total;
  //       monthSubtotalMap[month][thisYear] = subtotal;
  //     }
  //   }
  //   {
  //     const transaction = lastYearMonthlySummary[i];
  //     if (transaction) {
  //       const month = +(transaction._id);
  //       const total = transaction.total;
  //       const subtotal = transaction.subtotal;

  //       monthTotalMap[month][lastYear] = total;
  //       monthSubtotalMap[month][lastYear] = subtotal;
  //     }
  //   }
  // }

  // const weeklyChartData: Record<string, number>[] = [];
  // const yearlyTotal: Record<number, number> = {};
  // for (let year = FIRST_YEAR; year <= thisYear; year++) {
  //   if (weeklySummary[year]) {
  //     weeklySummary[year].sort((a: any, b: any) => { return a._id - b._id } );
  //   }
  //   yearlyTotal[year] = 0;
  // }

  // for (let i = 0; i < 54; i++) {
  //   const week: Record<string, number> = {};
  //   week['Week'] = i + 1;

  //   for (let year = FIRST_YEAR; year <= thisYear; year++) {
  //     if (weeklySummary[year]) {
  //       const mySummary = weeklySummary[year];
  //       if (mySummary.length > i) {
  //         week[year] = mySummary[i].subtotal / 100;
  //         yearlyTotal[year] += mySummary[i].subtotal / 100;
  //         week[year + ' (Total)'] = yearlyTotal[year];
  //         week[year + ' (Customers)'] = mySummary[i].count;
  //       }
  //     }
  //   }
  //   weeklyChartData.push(week);
  // }

  return (
    <div className="ags-main">
      <div className="ags-body">

      </div>
    </div>
  )
}

export default AnalysisPage