import React from 'react';

export interface IdentityState {
    accessToken?: string,
    roles?: string[],
    email?: string,
    firstname?: string,
    lastname?: string,
    city?: string,
    province?: string,
    postalCode?: string,
    country?: string,
    dob?: string
};

const IdentityContext = React.createContext<{
    identity?: IdentityState,
    setIdentity?: React.Dispatch<React.SetStateAction<IdentityState>>
}>({});
export default IdentityContext;
