import { useContext, useEffect, useState } from 'react';
import CategoryContext, { CategoryState } from '../../../contexts/CategoryContext';
import CategoryWidget from './CategoryWidget'
import { Link } from "react-router-dom";
import { useDrop } from 'react-dnd'
import { DnDTypes } from "../dnd/DnDTypes";
import IdentityContext from '../../auth/IdentityContext';
import IfAuthorized from '../../conditions/IfAuthorized';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import TextEntryDialog from '../../dialogs/TextEntryDialog';
import API from '../../../API';
import { Divider, Drawer, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';


type Props = {
  root: string
  group?: string
  selectedCategory?: string
  display?: string
  setDisplay?: React.Dispatch<React.SetStateAction<string>>
}

const CategoryPanel = ({ root, group, display, setDisplay, selectedCategory }: Props) => {
  const {identity, setIdentity} = useContext(IdentityContext);
  const { category, doCategoryRefresh } = useContext(CategoryContext);
  const [addingCategory, setAddingCategory] = useState<boolean>(false);

  const categoryState: CategoryState = category;
  const categories = categoryState.categories;


  const [{ canDrop: canDropCategory, isOver: isCategoryOver }, drop] = useDrop(() => ({
      accept: DnDTypes.CATEGORY,
      drop: () => ({id: 1, name: 'top'}),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop() && identity?.roles?.includes('editor'),
      }),
    })
  );

  const dropRef = identity?.roles?.includes('editor') ? drop : undefined;

    const addCategory = async (name: string): Promise<void> => {
      if (name) {
        console.log('Adding category: ' + name);
        const newCategory: ICategory = await API.createCategory(root, name, identity?.accessToken);
        console.log('  * ' + JSON.stringify(newCategory));
      }
      setAddingCategory(false);
      doCategoryRefresh();
    }

  const isActive = (canDropCategory && isCategoryOver);
  let backgroundColor = isActive ? 'lightGrey' : 'transparent';

  return (
    <div id="category-placement" style={{display}}>
      <div id="category-drawer">
      <div id="category-drawer-header">
        <IconButton
          onClick={() => {if (setDisplay) {setDisplay('none')}}}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <TextEntryDialog
        initialState={addingCategory}
        title="Create new top level category"
        text=""
        prompt="New category name"
        onCancel={() => setAddingCategory(false)}
        onOK={(value: string) => {addCategory(value); setAddingCategory(false);}}
        />
      <div>
      </div>
      <div className="categories">
        <div ref={dropRef} style={{backgroundColor}}>
          <Link to='/category/'>All Departments</Link>
          <IfAuthorized role='editor'>
            <IconButton
              className='tree-icon'
              aria-label="create category"
              onClick={() => setAddingCategory(true)}
              size="large">
              <CreateNewFolderIcon />
            </IconButton>
          </IfAuthorized>
        </div>
        <ul className="groups">
        {categories.map((category: ICategory) => (
          <CategoryWidget
            key={category.id}
            category = {category}
            group={group}
            selectedCategory={selectedCategory}
        />
        ))}
        </ul>
      </div>
      </div>
    </div>
  );
}

export default CategoryPanel