import React from 'react';
import { useContext, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import CountInput from './inputs/CountInput';
import PriceInput from './inputs/PriceInput';
import IdentityContext from '../auth/IdentityContext';
import API from '../../API';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { Link } from 'react-router-dom';

type Props = {
  item: IItem
}

const InventoryItemEditor = ({ item }: Props) => {
  const {identity, setIdentity} = useContext(IdentityContext);
  const [name, setName] = useState<string>(item.name)
  const [open, setOpen] = React.useState("");

  async function handleEditStock(key: string, value: number) {
    const newInventory = item.inventory;
    if (!newInventory[key]) {
      newInventory[key] = {} as any;
    }
    newInventory[key].count = value;

    await API.updateItemField(item.id, 'inventory', newInventory, identity?.accessToken);
    setOpen(key + " stock");
  }

  async function handleEditName(event: any) {
    setName(event.target.value);
  }

  async function commitName() {
    await API.updateItemField(item.id, 'name', name, identity?.accessToken)
    setOpen("name");
  }

  async function handleEditCost(value: number) {
    await API.updateItemField(item.id, 'cost', value, identity?.accessToken)
    setOpen("cost");
  }

  async function handleEditPrice(value: number) {
    await API.updateItemField(item.id, 'price', value, identity?.accessToken)
    setOpen("price");
  }

  const handleClose = () => {
    setOpen("");
  };

  return (
    <>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={!!open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Saved changes ({open}).
        </Alert>
      </Snackbar>

      <TableRow>
        {/* <TableCell><TextField InputLabelProps={{ shrink: true }} id="edit-name" label="Name" value={name} onChange={handleEditName} onBlur={commitName}/></TableCell> */}
        <TableCell>
          <a href={`${process.env.REACT_APP_AGS_ADMIN}/admin/${API.getStore()}/item/${item.id}`} target="_blank" rel="noreferrer">
            {item.name}
          </a>
        </TableCell>
        <TableCell>{item.codes?.upc ? item.codes?.upc : item.codes?.sku}</TableCell>
        <TableCell><PriceInput id="edit-cost" label="Cost" value={item.cost} onCommit={handleEditCost}/></TableCell>
        <TableCell><PriceInput id="edit-price" label="Price" value={item.price} onCommit={handleEditPrice}/></TableCell>
        <TableCell>
          <CountInput resetAt={item.inventory["liverpool"]?.resetAt} handleCommit={(value: number) => handleEditStock("liverpool", value)} label="Lvpl" initial={item.inventory["liverpool"] ? item.inventory["liverpool"].count : 0}/>
          <CountInput resetAt={item.inventory["bridgewater"]?.resetAt} handleCommit={(value: number) => handleEditStock("bridgewater", value)} label="Bwtr" initial={item.inventory["bridgewater"] ? item.inventory["bridgewater"].count : 0}/>
          <CountInput resetAt={item.inventory["warehouse"]?.resetAt} handleCommit={(value: number) => handleEditStock("warehouse", value)} label="Ware" initial={item.inventory["warehouse"] ? item.inventory["warehouse"].count : 0}/>
          <CountInput resetAt={item.inventory["bongbus"]?.resetAt} handleCommit={(value: number) => handleEditStock("bongbus", value)} label="Bus" initial={item.inventory["bongbus"] ? item.inventory["bongbus"].count : 0}/>
          <CountInput resetAt={item.inventory["overflow"]?.resetAt} handleCommit={(value: number) => handleEditStock("overflow", value)} label="Err" initial={item.inventory["overflow"] ? item.inventory["overflow"].count : 0}/>
        </TableCell>
      </TableRow>
    </>
  );
}

export default InventoryItemEditor