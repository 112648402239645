import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Shop from '../widgets/shop/Shop';
import CategoryPanel from '../widgets/category/CategoryPanel';
import ConfigContext from '../../contexts/ConfigContext';
import React from 'react';
import ItemDetailsPage from './ItemDetailsPage';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import CategoryIcon from '@mui/icons-material/Category';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import { useHistory } from "react-router-dom";
import IfAuthorized from '../conditions/IfAuthorized';
import IfNotAuthorized from '../conditions/IfNotAuthorized';
import ItemEditorPage from './ItemEditorPage';
import API from '../../API';

type Props = {
  areFiltersOpen: boolean,
  setAreFiltersOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Store = ({ areFiltersOpen, setAreFiltersOpen }: Props) => {
  const history = useHistory();
  const {config} = useContext(ConfigContext);
  const [defaultDisplay, setDefaultDisplay] = React.useState<string | undefined>(undefined);

  let { categoryId } = useParams<{categoryId: string}>();
  let { group } = useParams<{group: string}>();
  const categoryRoot = config.general.root_category;
  const useGroup = group ? group : categoryId && !categoryId.startsWith('#') ? categoryId : undefined;

  const filterRef = React.createRef<HTMLDivElement>();

  const [categoryManagerDisplay, setCategoryManagerDisplay] = useState<string>("none");


  const ACTION_ADD_NEW_ITEM = 'Add New Item';
  const ACTION_FILTER = "Filters";
  const ACTION_CATEGORY_MANAGER = "Category Manager";
  const ACTION_BRAND_MANAGER = "Brand Manager";

  const ADMIN_ACTIONS = [
    { icon: <FilterListIcon />, name: ACTION_FILTER },
    { icon: <CategoryIcon />, name: ACTION_BRAND_MANAGER },
    { icon: <PermMediaIcon />, name: ACTION_CATEGORY_MANAGER },
    { icon: <AddIcon />, name: ACTION_ADD_NEW_ITEM },
  ];
  
  useEffect(() => {
    if (filterRef && filterRef.current) {
      const myDisplay = filterRef.current.style.getPropertyValue("display");
      const calculatedDisplay = window.getComputedStyle(filterRef.current).getPropertyValue("display");
      if (myDisplay !== calculatedDisplay) {
        switch (calculatedDisplay) {
          case 'block':
            setAreFiltersOpen(true);
            setDefaultDisplay('block');
            break;
          default:
            setAreFiltersOpen(false);
            setDefaultDisplay('none');
            break;
        }
      }
    }
  }, [filterRef]);

  const doAction = (name: string) => {
    switch (name) {
      case ACTION_ADD_NEW_ITEM:
        console.log(`history.push(/item/new)`);
        window.open(`${process.env.REACT_APP_AGS_ADMIN}/admin/${API.getStore()}/item/`, "_blank", "noreferrer");
        break;
      case ACTION_CATEGORY_MANAGER:
        setCategoryManagerDisplay('flex');
        break;
      case ACTION_FILTER:
        break;
    }
  }

  // Only set the hideStyle if the default display has been determined
  const hideStyle = !!defaultDisplay ? {
    display: areFiltersOpen ? 'block' : 'none'
  } : {};

  return (
    <>
      <IfAuthorized role='editor'>
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{ position: 'fixed', bottom: 16, left: 16 }}
          icon={<SpeedDialIcon />}
        >
          {ADMIN_ACTIONS.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={e => {
                e.stopPropagation();
                doAction(action.name);
              }}
            />
          ))}
        </SpeedDial>
        <ItemEditorPage />
      </IfAuthorized>
      <IfNotAuthorized role='editor'>
        <ItemDetailsPage />
      </IfNotAuthorized>
      <div className="store-parent">
        <CategoryPanel display={categoryManagerDisplay} setDisplay={setCategoryManagerDisplay} root={categoryRoot} group={useGroup} selectedCategory={categoryId}/>
        <div className="store-main">
          <Shop />
        </div>
      </div>
    </>
  )
}

export default Store