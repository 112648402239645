import React from 'react';
import InputBase from '@mui/material/InputBase/InputBase';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';

type Props = {
    display: string
    onSearch?: (value: string) => void
    defaultSearch?: string
  }
  
  const SearchField = ({ display, onSearch, defaultSearch }: Props) => {
    const history = useHistory();
    const [searchValue, setSearchValue] = React.useState(defaultSearch);

    const onSearchKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (event.key === 'Enter') {
            const text = (event.target as HTMLInputElement).value;
            // const searchText = text.replace(/[^\w ]/g, '.').trim();
            const encodedText = encodeURIComponent(text);
            const searchText = text;
            console.log("SEARCH TEXT: " + text + " :: " + searchText)
            setSearchValue(searchText);
            if (searchText) {
                if (onSearch) {
                    onSearch(encodedText);
                }
                else {
                    console.log(`history.push(/search/${encodedText})`); 
                    history.push(`/search/${encodedText}`);
                }
            }
        }
    }

    const updateSearchField = (event: any) => {
        setSearchValue(event.target.value);
    }

    
    switch (display) {
        case "desktop":
            return (
                <>
                    <div className="header-search header-search-desktop">
                        <div className="header-search-icon">
                            <SearchIcon />
                        </div>
                        <InputBase
                        placeholder="Search…"
                        className="header-input-root header-input-input"
                        inputProps={{ 'aria-label': 'search' }}
                        value={searchValue}
                        onChange={updateSearchField}
                        onKeyDown={onSearchKeyDown}
                        />
                    </div>
                </>
            );

            case "mobile":
                return (
                    <>
                        <div className="header-search header-search-mobile">
                            <div className="header-search-icon">
                                <SearchIcon />
                            </div>
                            <InputBase
                            placeholder="Search…"
                            className="header-input-root header-input-input"
                            inputProps={{ 'aria-label': 'search' }}
                            value={searchValue}
                            onKeyDown={onSearchKeyDown}
                            />
                        </div>
                    </>
                );
    
    }

    return (
        <>
        </>
    );
}

export default SearchField
