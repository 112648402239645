import React, { useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { loginRequest } from '../../auth/AuthUtils';
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

type Props = {
    handleLoginMenu: () => void,
    handleLogoutMenu: () => void,
    handleProfileMenu: () => void
}

const UserMenu = React.forwardRef(({ handleLoginMenu, handleLogoutMenu, handleProfileMenu }: Props, ref) => {
    const [loggedIn, setLoggedIn] = React.useState(false);
    const { instance, accounts, inProgress } = useMsal();

    const handleLogin = async (instance: any) => {
        instance.acquireTokenRedirect(loginRequest).catch((e: any) => {
            console.error(e);
        });
    }

    const handleLogout = async (instance: any) =>  {
        instance.logoutRedirect().catch((e: any) => {
            console.error(e);
        });
    }


    return (
        <>
            <AuthenticatedTemplate>
                <MenuItem onClick={() => handleLogout(instance)}>Logout</MenuItem>
                <MenuItem onClick={handleProfileMenu}>Profile</MenuItem>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <MenuItem onClick={() => handleLogin(instance)}>Login</MenuItem>
            </UnauthenticatedTemplate>
        </>
    )    
});

export default UserMenu
