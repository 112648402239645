import * as React from 'react';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

type Props = {
    initialState: boolean,
    title: string,
    text: string,
    onYes?: () => void,
    onNo?: () => void
}

export default function AlertDialog({ initialState, title, text, onYes, onNo }: Props) {
  const [open, setOpen] = React.useState(initialState);

  if (initialState !== open) {
      setOpen(initialState);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNo = () => {
    setOpen(false);
    if (onNo) {
        onNo();
    }
  };

  const handleYes = () => {
    setOpen(false);
    if (onYes) {
        onYes();
    }
  };

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">
            {title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {text}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleNo}>No</Button>
            <Button onClick={handleYes} autoFocus>Yes</Button>
        </DialogActions>
    </Dialog>
  );
}