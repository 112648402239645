import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../API';
import InvoiceReviewPanel from '../widgets/invoice/InvoiceReviewPanel';
import IdentityContext from '../auth/IdentityContext';
import { SelectChangeEvent } from '@mui/material';

const InvoicePage = () => {
  let { dbName } = useParams<{dbName: string}>();
  let { orderId } = useParams<{orderId: string}>();
  const [order, setOrder] = useState<IOrder|undefined>();
  const {identity} = useContext(IdentityContext);

  const accessToken = identity?.accessToken;
    // Submit only once
    useEffect(() => {
      console.log("BUH");
      const doSubmitOrder = async() => {
        console.log("GUH: " + orderId);
        if (orderId) {
          const order = await API.getOrderById(dbName, orderId, accessToken);
          if (order) {
            // If the order is submitted, clear the cart
            setOrder(order);
          }
        }
      }
  
      doSubmitOrder();
    }, [accessToken, identity, orderId]);

  if (!order) {
    return (
      <div className='receipt-parent'>
        <div className='receipt-main'>
          Loading order...
        </div>
      </div>
    );
  }

  const handleStateChange = async(event: SelectChangeEvent<{ value: unknown }>) => {
    //identity?.accessToken

    const newState = event.target.value as string;
    await API.setOrderStateById(dbName, orderId, newState, accessToken);
    setOrder({
      ...order,
      state: newState
    })
  }

  return (
    <div className='receipt-parent'>
      <div className='receipt-main'>
        <InvoiceReviewPanel order={order as IOrder} handleStateChange={handleStateChange}/>
       </div>
     </div>
    )
}

export default InvoicePage