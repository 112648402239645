import React, { useContext, useEffect, useState } from 'react';
import { Prompt, useParams } from 'react-router-dom';
import IdentityContext from '../auth/IdentityContext';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CountInput from '../widgets/inputs/CountInput';
import API from '../../API';
import { useHistory } from "react-router-dom";
import PriceInput from '../widgets/inputs/PriceInput';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CategorySelector from '../widgets/category/CategorySelector';
import CardHeader from '@mui/material/CardHeader';
import ProgressOverlay from '../overlays/ProgressOverlay';

const NewItemPanel = () => {
    const history = useHistory();
    let [isBlocking, setIsBlocking] = useState(false);
    let { itemId } = useParams<{itemId: string}>();
    const [saveChanges, setSaveChanges] = useState<boolean>(false);

    const [item, setItem] = useState<IItem>({
        id: '',
        name: '',
        description: '',
        imageId: '',
        categoryId: '',
        supplierUrls: [],
        homeUrls: [],
        externalId: {},
        inventory: {}
    });

    const [override, setOverride] = useState<IEditableItem>({
        id: '',
        name: '',
        description: '',
        categoryId: '',
        brand: '',
        price: -1,
        cost: -1,
        supplierUrls: [],
        distributorUrl: '',
        homeUrls: [],
        externalId: {},
        inventory: {},
        codes: {
            upc: '',
            sku: ''
        },
        group: {
            enabled: false,
            groupId: '',
            variantName: '',
            imageUrl: ''
        }
    });

    const [imageUrl, setImageUrl] = useState<string>('');
    const [supplierUrl, setSupplierUrl] = useState<string>(item.supplierUrls.length > 0 ? item.supplierUrls[0] : '');
    const {identity, setIdentity} = useContext(IdentityContext);

    useEffect(() => {
        /**
         * DO actual calls for create
         */
        const doCreate = async() => {
            if (!override.name) {
                // fail
                console.log("ERROR: Must provide name");
                setSaveChanges(false);
                return;
            }
            try {
                if (identity?.accessToken) {
                    const result = await API.createItem(override, identity.accessToken);
        
                    // Some fields need extra handling
                    if (imageUrl) {
                        const tokens = imageUrl.split('?');
                        await API.cloneImage(result.id, tokens[0], identity.accessToken);
                    }
        
                    // Do this one last so that it updates only empty fields
                    if (supplierUrl) {
                        if (item.supplierUrls.length === 0) {
                            await API.updateItemField(result.id, 'supplierUrl', supplierUrl, identity.accessToken);
                        }
                        else if (item.supplierUrls[0] !== supplierUrl) {
                            await API.updateItemField(result.id, 'supplierUrl', supplierUrl, identity.accessToken);
                        }
                    }
                    else if (item.supplierUrls.length > 0) {
                        await API.updateItemField(result.id, 'supplierUrl', '', identity.accessToken);
                    }

                    await API.syncItem(result, identity?.accessToken);
                            
                    setIsBlocking(false);
                    setSaveChanges(false);
                    console.log(`history.push(${'/item/' + result.id})`);
                    history.push('/item/' + result.id);
                }
            }
            catch (err: any) {
                console.log('ERROR: ' + JSON.stringify(err));
                setSaveChanges(false);
            }
        }

        if (saveChanges && identity?.accessToken) {
            doCreate();
        }
    }, [history, identity?.accessToken, imageUrl, item.supplierUrls, override, saveChanges, supplierUrl]);

    useEffect(() => {
        const fetchItem = async () => {
          try {
            
            const item = await API.getItemById(itemId, identity?.accessToken);
            const newItem = {
                id: '',
                name: item.name,
                description: item.description ? item.description : '',
                categoryId: item.categoryId,
                brand: item.brand ? item.brand : '',
                price: item.price ? item.price : -1,
                cost: item.cost ? item.cost : -1,
                supplierUrls: item.supplierUrls,
                distributorUrl: item.distributorUrl ? item.distributorUrl : '',
                homeUrls: item.homeUrls,
                externalId: item.externalId,
                discontinued: item.discontinued,
                hidden: item.hidden,
                special: item.special,
                notes: item.notes,
                inventory: {},
                codes: {
                    upc: '',
                    sku: ''
                },
                group: {
                    enabled: item.group?.enabled ? item.group.enabled : false,
                    groupId: item.group?.groupId ? item.group.groupId : '',
                    variantName: item.group?.variantName ? item.group.variantName : '',
                    imageUrl: item.group?.imageUrl ? item.group?.imageUrl : ''
                }      
            };
            setOverride(newItem);

            if (item.supplierUrls && item.supplierUrls.length > 0) {
                setSupplierUrl(item.supplierUrls[0]);
            }
          }
          catch (err: any) {
          }
        }
        fetchItem();
      }, [itemId, identity?.accessToken]);

    /**
     * Don't pass this point if we are not in the editor group
     */
    if (!identity?.roles?.includes('editor')) {
        return (
            <>
            </>
        )
    }
    
    const handleEditUpc = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOverride({
            ...override,
            codes: {
                ...override.codes,
                upc: event.target.value
            }
        });
        setIsBlocking(true);
    }
    
    const handleEditStock = (key: string, value: number) => {
        const inventory: InventoryItem = override.inventory[key] ? override.inventory[key] : {} as InventoryItem;
        inventory.count = value;

        const newOverride = {
            ...override
        }

        newOverride.inventory[key] = inventory;
        setOverride(newOverride);
        setIsBlocking(true);
    }

    const handleEditDistributor = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOverride({
            ...override,
            distributorUrl: event.target.value
        });
        setIsBlocking(true);
    }

    const handleEditBrand = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOverride({
            ...override,
            brand: event.target.value
        });
        setIsBlocking(true);
    }
    
    const handleEditName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOverride({
            ...override,
            name: event.target.value
        });
        setIsBlocking(true);
    }
    
    const handleEditCost = (value: number) => {
        setOverride({
            ...override,
            cost: value
        });
        setIsBlocking(true);
    }
    
    const handleEditPrice = (value: number) => {
        setOverride({
            ...override,
            price: value
        });
        setIsBlocking(true);
    }

    const handleEditCategory = (categoryId?: string) => {
        if (!categoryId) {
            categoryId = '';
        }
        setOverride({
            ...override,
            categoryId: categoryId
        })
        setIsBlocking(true);
    }
    
    const handleEditSupplier = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSupplierUrl(event.target.value);
        setIsBlocking(true);
    }
    
    const handleEditImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setImageUrl(event.target.value);
        setIsBlocking(true);
    }
    
    const handleEditDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOverride({
            ...override,
            description: event.target.value
        });
        setIsBlocking(true);
    }

    const handleEditNotes = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOverride({
            ...override,
            notes: event.target.value
        });
        setIsBlocking(true);
    }

    const handleEditHidden = ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setOverride({
            ...override,
            hidden: checked
        });
        setIsBlocking(true);
    });
    
    const handleEditSpecial = ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setOverride({
            ...override,
            special: checked
        });
        setIsBlocking(true);
    });
    
    const handleEditDiscontinued = ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setOverride({
            ...override,
            discontinued: checked
        });
        setIsBlocking(true);
    });
    
    /**
     * The save operation is different if this is a new item vs.
     * an existing item.
     */
    const handleSave = async () => {
        setSaveChanges(true);
    }
    
    const buttonText = 'CREATE ITEM';

    /**
     * We are an editor. Huzzah!
     */
    return (
        <>
        <ProgressOverlay enabled={saveChanges}>
            <Card className={"item-edit"}>
            <CardContent>
            <form noValidate autoComplete="off"
                onSubmit={event => {
                    event.preventDefault();
                    setIsBlocking(false);}}>
                <Prompt
                    when={isBlocking}
                    message={location =>
                    `You have made changes to this item that have not been saved. Are you sure you want to go?`
                    }
                />
                <div>
                Item editor
                </div>
                <div color="textSecondary">
                    ID: {item.id}
                </div>

                <Card className='group editor-card'>
                    <CardHeader className='group-header' title="Identification"/>
                    <CardContent className='content'>
                    <TextField InputLabelProps={{ shrink: true }} id="edit-upc" label="UPC" value={override.codes.upc} onChange={handleEditUpc}/>
                    </CardContent>
                </Card>

                <div className='item-editor-fields'>
                    <div className="item-editor-text-fields"><TextField InputLabelProps={{ shrink: true }} id="edit-brand" label="Brand" value={override.brand} onChange={handleEditBrand}/></div>
                    <div className="item-editor-text-fields"><TextField InputLabelProps={{ shrink: true }} id="edit-name" label="Name" value={override.name} onChange={handleEditName}/></div>
                    <div className="item-editor-text-fields"><CategorySelector value={override.categoryId} onChange={handleEditCategory}/></div>
                </div>

                <Card className='group editor-card'>
                    <CardHeader className='group-header' title="Cost/Price"/>
                    <CardContent className='content'>
                        <PriceInput id="edit-cost" label="Cost" value={override.cost} onCommit={handleEditCost}/>
                        <PriceInput id="edit-price" label="Price" value={override.price} onCommit={handleEditPrice}/>
                    </CardContent>
                </Card>

                <Card id='stock-count' className='group editor-card'>
                    <CardHeader className='group-header' title="Stock Count" subheader="By location"/>
                    <CardContent className='content'>
                        <CountInput id="edit-stock" handleCommit={(value: number) => handleEditStock("liverpool", value)} label="Lvpl" initial={override.inventory["liverpool"] ? override.inventory["liverpool"].count : 0}/>
                        <CountInput id="edit-stock" handleCommit={(value: number) => handleEditStock("bridgewater", value)} label="Bwtr" initial={override.inventory["bridgewater"] ? override.inventory["bridgewater"].count : 0}/>
                        <CountInput id="edit-stock" handleCommit={(value: number) => handleEditStock("warehouse", value)} label="Ware" initial={override.inventory["warehouse"] ? override.inventory["warehouse"].count : 0}/>
                        <CountInput id="edit-stock" handleCommit={(value: number) => handleEditStock("bongbus", value)} label="Bus" initial={override.inventory["bongbus"] ? override.inventory["bongbus"].count : 0}/>
                    </CardContent>
                </Card>


                <Card id='source-links' className='group editor-card'>
                    <CardHeader className='group-header' title="Links"/>
                    <CardContent className='card-content-full-width'>
                        <div className="item-editor-text-fields"><TextField InputLabelProps={{ shrink: true }} id="edit-supplierUrl" label="Supplier Link" value={supplierUrl} onChange={handleEditSupplier}/></div>
                        <div className="item-editor-text-fields"><TextField InputLabelProps={{ shrink: true }} id="edit-distributorUrl" label="Manufacturer Link" value={override.distributorUrl} onChange={handleEditDistributor}/></div>
                        <div className="item-editor-text-fields"><TextField InputLabelProps={{ shrink: true }} id="edit-imageUrl" label="New Image URL" value={imageUrl} onChange={handleEditImage}/></div>
                    </CardContent>
                </Card>

                <div className='item-editor-fields'>
                    <div className="item-editor-text-fields">
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={override.hidden}
                            onChange={handleEditHidden}
                            name="checkedHidden"
                        />
                        }
                        label="Hidden"
                    />
                    </div>

                    <div className="item-editor-text-fields">
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={override.special}
                            onChange={handleEditSpecial}
                            name="checkedSpecial"
                        />
                        }
                        label="Special Order"
                    />
                    </div>

                    <div className="item-editor-text-fields">
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={override.discontinued}
                            onChange={handleEditDiscontinued}
                            name="checkedDiscontinued"
                        />
                        }
                        label="Discontinued"
                    />
                    </div>



                </div>
                <div id="edit-description" className='edit-textarea'>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    label="Description"
                    multiline
                    rows={10}
                    defaultValue={override.description}
                    onChange={handleEditDescription}
                    />
                </div>
                <div id="edit-notes" className='edit-textarea'>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    label="Notes"
                    multiline
                    rows={2}
                    defaultValue={override.notes}
                    onChange={handleEditNotes}
                    />
                </div>
            </form>
            </CardContent>
            <CardActions>
            <Button variant="contained" color="primary" onClick={() => handleSave()}>
                {buttonText}
            </Button>
            </CardActions>
        </Card>
      </ProgressOverlay>
      </>
    )
}

export default NewItemPanel
