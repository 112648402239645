import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IfTrue from '../conditions/IfTrue';

type Props = {
    initialState: boolean,
    title: string,
    text: string,
    prompt: string,
    onCancel: () => void,
    onOK: (value: string) => void
}


export default function TextEntryDialog({ initialState, title, text, prompt, onCancel, onOK }: Props) {
    const [open, setOpen] = React.useState(initialState);
    const [value, setValue] = React.useState('');

  if (initialState !== open) {
    setOpen(initialState);
    setValue('');
  }

  const handleCancel = () => {
      onCancel();
  };

  const handleOK = () => {
    setOpen(false);
    onOK(value)
  };

  return (
    <div>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <IfTrue condition={!!text}>
            <DialogContentText>
                {text}
            </DialogContentText>
          </IfTrue>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={prompt}
            value={value}
            onChange={(event) => setValue(event.target.value)}
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleOK}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}