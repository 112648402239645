import React, { useEffect, useState, useContext } from 'react';
import { Button, FormControl, FormLabel, SelectChangeEvent, TextField } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import PriceInput from '../../widgets/inputs/PriceInput';
import ConfigContext from '../../../contexts/ConfigContext';
import Price from '../../widgets/shop/Price';
import ShippingClient from '../../../api/ShippingClient';
import ProvinceSelector from '../../widgets/inputs/ProvinceSelector';

const ShippingEstimatePage = () => {
  const [province, setProvince] = useState<string>('');
  const [street, setStreet] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [postal, setPostal] = useState<string>('');
  const [price, setPrice] = useState<number>(0);
  const [shipping, setShipping] = useState<ShippingDetails[]>([]);
  const {config} = useContext(ConfigContext);

  const baseUrl = process.env.REACT_APP_API_HOST as string;

  const onStreetChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStreet(event.target.value);
  }

  const onCityChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCity(event.target.value as string);
  }

  const onProvinceChanged = (value: string) => {
    setProvince(value as string);
  };

  const onPostalChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPostal(value.toUpperCase());
  }

  const onPriceChanged = (value: number) => {
    setPrice(value);
  }

  const calculateShipping = async () => {
    const shippingDetailsList = await ShippingClient.getRawShippingEstimate(province, postal, price);
    setShipping(shippingDetailsList);
  }

  useEffect(() => {
  }, []);

  return (
    <div className='shipping-estimator-parent'>
      <Card>
        <CardContent>
          <FormControl component="fieldset">
            <FormLabel component="legend" className='checkout-form-legend'>Delivery details</FormLabel>
              <div className='checkout-form-fields'>
                {/* <TextField required id="street" label="Street Address" value={street} onChange={onStreetChanged}/> */}
                {/* <TextField id="additional" label="Additional" value={override.additional} onChange={onAdditionalChanged} onBlur={onBlur}/> */}
                {/* <TextField required id="city" label="City" value={city} onChange={onCityChanged}/> */}
                <ProvinceSelector
                  province={province}
                  onChange={onProvinceChanged}
                  />
                  <FormControl>
                    <TextField
                      label="Postal Code"
                      value={postal}
                      onChange={onPostalChanged}
                      name="postal"
                      id="postal"
                      // inputComponent={TextMaskCustom as any}
                    />
                  </FormControl>
              </div>
              <p/>
              <PriceInput value={price} onCommit={onPriceChanged} label='Price'/>
              <p/>
              <Button variant="outlined" onClick={calculateShipping}>Calculate Shipping</Button>
              <table>
                <tr>
                  <th>Name</th>
                  <th>Code</th>
                  <th>Shipping</th>
                  <th>Discount</th>
                  <th>Discounted Shipping</th>
                  <th>Min Days</th>
                  <th>Max Days</th>
                  <th>Free Shipping At</th>
                </tr>
              {shipping.map((option: ShippingDetails) => {
                const cost = price / 2;
                const discount = cost / 2;
                const finalPrice = (option.price - discount) > 0 ? option.price - discount : 0;
                return (
                  <tr>
                  <td>{option.name}</td>
                  <td>{option.code}</td>
                  <td><Price price={option.price}/></td>
                  <td><Price price={discount}/></td>
                  <td><Price price={finalPrice}/></td>
                  <td>{option.minDays}</td>
                  <td>{option.maxDays}</td>
                  <td><Price price={option.freeShippingThreshold}/></td>
                </tr>
                );
              })}
              </table>
            </FormControl>
        </CardContent>
      </Card>
    </div>
  )
}

export default ShippingEstimatePage