import React, { useContext } from 'react';
import PrimarySearchAppBar from './quickbar/PrimarySearchAppBar';
import ConfigContext from '../../contexts/ConfigContext';

type Props = {
  areFiltersOpen: boolean,
  setAreFiltersOpen: React.Dispatch<React.SetStateAction<boolean>>,
  deptOpen: boolean,
  setDeptOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Header = ({ deptOpen, setDeptOpen, areFiltersOpen, setAreFiltersOpen }: Props) => {
  const {config} = useContext(ConfigContext);

  const fontStyle = {} as React.CSSProperties;
  if (config.general?.fonts?.menu) {
    fontStyle['fontFamily'] = config.general.fonts.menu;
    fontStyle['textTransform'] = 'uppercase';
  }

  let bufferClasses=['buffer'];
  let navbarClasses=['navbar'];

  return (
    <>
    <div id="header" className={navbarClasses.join(" ")}>
      <PrimarySearchAppBar deptOpen={deptOpen} setDeptOpen={setDeptOpen} areFiltersOpen={areFiltersOpen} setAreFiltersOpen={setAreFiltersOpen}/>
    </div>
    <div id='buffer' className={bufferClasses.join(" ")}>

    </div>
    </>
  )
}

export default Header;