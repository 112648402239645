import { IconButton } from '@mui/material';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import IdentityContext from '../auth/IdentityContext';
import NewItemPanel from '../panels/NewItemPanel';
import CloseIcon from '@mui/icons-material/Close';

const NewItemPage = () => {
  const history = useHistory();
  const {identity, setIdentity} = useContext(IdentityContext);


  if (!identity?.roles?.includes('editor')) {
    return (
        <>
        </>
    )
  }

  const goBack = () => {
    history.goBack();
  }

return (
    <>
      <div className="overlay" onClick={goBack}></div>
      <div id='id-main'>
        <div className="header-bar">
          <div>Create New Item</div>
          <div className="spacer"></div>
          <IconButton
            className="close-icon"
            aria-label="back"
            onClick={goBack}
            size="large">
            <CloseIcon />
          </IconButton>
        </div>
        <div className="dialog-body">
          <NewItemPanel/>
        </div>
      </div>
    </>
  )
}

export default NewItemPage