import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';

type Props = {
  cust: CardholderAddress | undefined
}

const ReviewBillingAddress = ({ cust }: Props) => {

  if (!cust) {
    return (
      <>
      </>
    )
  }

  return (
    <Card>
      <CardContent>
      <Typography variant="h5" component="h2">Customer Contact</Typography>

      <TableContainer component={Paper}>
          <Table size="small" aria-label="simple table">
            <TableBody>
              <TableRow><TableCell>Name</TableCell><TableCell>{cust.firstName} {cust.lastName}</TableCell></TableRow>
              <TableRow><TableCell>Country</TableCell><TableCell>{cust.country}</TableCell></TableRow>
              <TableRow><TableCell>Postal Code</TableCell><TableCell>{cust.postal}</TableCell></TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  )
}

export default ReviewBillingAddress