import { Link } from "react-router-dom";

type Props = {
  name: string,
  id: string
}

const InvoicedItemLink = ({ id, name }: Props) => {

  if (name.endsWith('Shipping')) {
    return (
      <div className='item-link'>{name}</div>
    );
  }

  return (
        <div className='item-link'><Link to={'/item/' + id}>{name}</Link></div>
  )
}

export default InvoicedItemLink