import React, { useEffect, useState, useContext } from 'react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from './AuthUtils';
import IdentityContext, { IdentityState } from './IdentityContext';
import API from '../../API';
import ConfigContext from '../../contexts/ConfigContext';
import { useLocation, useParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { Snackbar } from '@mui/material';

/**
 * Gather whatever identity information we can and make it
 * available.
 */
const IdentityTemplate : React.FunctionComponent<{children: React.ReactNode;}> = props  => {
    const { instance, accounts, inProgress } = useMsal();  
    const [identity, setIdentity] = useState<IdentityState>({});
    const {config, setConfig} = useContext(ConfigContext);
    const [fetchedIdentity, setFetchedIdentity] = useState(false);

    const location = useLocation();

    const [open, setOpen] = React.useState<boolean>(false);

    const handleClose = (reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setIdentity({});
      setOpen(false);
    };
  
 /**
   * Get the items, but only if we can get the accessToken
   */
   useEffect(() => {
        const fetchRoles = async (accessToken?: string) => {
            const roles = await API.getRoles(accessToken);
            console.log("ROLES: " + JSON.stringify(roles));
            return roles;
        }

        const doFetchIdentity = async () => {
            console.log("Fetching identity " + location.pathname + " " + (accounts && accounts.length > 0 && accounts[0] ? accounts[0].username : ''));
            if (inProgress === 'none') {
                const request = {
                    ...loginRequest,
                    account: accounts[0]
                };
                // Silently acquires an access token which is then attached to a request for Microsoft Graph data
                try {
                    if (instance && identity) {
                        if (accounts.length > 0 && accounts[0]) {
                            const response = await instance.acquireTokenSilent(request);
                            const tokenClaims: any = accounts[0].idTokenClaims;
                            const roles = await fetchRoles(response.accessToken);

                            setIdentity({
                                ...identity,
                                accessToken: response.accessToken,
                                email: accounts[0].username,
                                firstname: tokenClaims?.given_name,
                                lastname: tokenClaims?.family_name,
                                city: tokenClaims?.city,
                                province: tokenClaims?.state,
                                postalCode: tokenClaims?.postalCode,
                                country: tokenClaims?.country,
                                dob: tokenClaims?.extension_dateOfBirth,
                                roles: roles
                            });
                        }
                    }
                }
                catch(e) {
                    console.log("Cannot get access token: " + e);
                };
                setFetchedIdentity(true);
            }
        }

        doFetchIdentity();
    }, [location, accounts, inProgress]);

    useEffect(() => {
        const fetchConfig = async () => {
            try {
            const myConfig = await API.getClientConfig("client", identity.accessToken);
            setConfig(myConfig);
        }
        catch (err: any) {
            console.error(err.message);
            }
        }
        
        if (fetchedIdentity) {
            fetchConfig();
        }
    }, [fetchedIdentity, identity.accessToken, setConfig])    
    
    if (!fetchedIdentity || !config.general) {
        return (
            <div>
                Loading... please wait.
            </div>
        )
    }

    if ((!accounts || !accounts.length || !accounts[0]) && !!identity.accessToken) {
        setOpen(true);
    }

    return (
        <IdentityContext.Provider value={{ identity, setIdentity }}>
            <Snackbar open={!!open} onClose={(event?: any, reason?: string) => {handleClose(reason)}}>
            <Alert onClose={(event?: any, reason?: string) => {handleClose(reason)}} severity="success">
                Login expired. Please re-login.
            </Alert>
            </Snackbar>
            {props.children}
        </IdentityContext.Provider>
    )
}

export default IdentityTemplate
