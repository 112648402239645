import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Link, Prompt } from 'react-router-dom';
import IdentityContext from '../auth/IdentityContext';
import CountInput from '../widgets/inputs/CountInput';
import API from '../../API';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { IconButton, Snackbar, TableBody } from '@mui/material';

import DoubleClickTextField from '../widgets/inputs/DoubleClickTextField';
import EditablePriceField from '../widgets/inputs/EditablePriceField';
import EditableMarkdownField from '../widgets/inputs/EditableMarkdownField';

import ConfigContext from '../../contexts/ConfigContext';
import { Table } from 'react-bootstrap';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ItemImage from '../widgets/shop/ItemImage';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import MoreVertIcon from '@mui/icons-material/MoreVert';

type Props = {
    parent?: IItem;
    variant: IItem;
}

const VariantRow = ({variant, parent}: Props) => {

    const [open, setOpen] = React.useState(false);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const {config} = useContext(ConfigContext);
    const root = config.general.root_category;

    const {identity, setIdentity} = useContext(IdentityContext);
    const [imageUrl, setImageUrl] = useState<string>(variant.imageId ? 'https://thequeensflorist.sirv.com/' + root + '/' + variant.imageId + '.jpg' : "");
    const [supplierUrl, setSupplierUrl] = useState<string>(variant.supplierUrls.length > 0 ? variant.supplierUrls[0] : '');

    const toEditableItem = (item: IItem): IEditableItem => {
        return {
            id: item.id,
            name: item.name ? item.name : '',
            description: item.description ? item.description : '',
            categoryId: item.categoryId ? item.categoryId : '',
            brand: item.brand ? item.brand : '',
            price: item.price ? item.price : -1,
            cost: item.cost ? item.cost : -1,
            supplierUrls: item.supplierUrls,
            distributorUrl: item.distributorUrl ? item.distributorUrl : '',
            homeUrls: item.homeUrls,
            externalId: item.externalId,
            inventory: item.inventory ? JSON.parse(JSON.stringify(item.inventory)) : {},
            discontinued: item.discontinued ? item.discontinued : false,
            hidden: item.hidden ? item.hidden : false,
            special: item.special ? item.special : false,
            notes: item.notes ? item.notes : '',
            codes: {
                upc: item.codes?.upc,
                sku: item.codes?.sku
            },
            group: {
                enabled: item.group?.enabled ? item.group.enabled : false,
                groupId: item.group?.groupId ? item.group.groupId : '',
                variantName: item.group?.variantName ? item.group.variantName : '',
                imageUrl: item.group?.imageUrl ? item.group?.imageUrl : ''
            }
        }
    }

    const [override, setOverride] = useState<IEditableItem>(toEditableItem(variant));

    const handleEditStock = async (key: string, value: number) => {
        const inventory: InventoryItem = override.inventory[key] ? override.inventory[key] : {} as InventoryItem;
        inventory.count = value;

        const newOverride = {
            ...override
        }
        newOverride.inventory[key] = inventory;
        setOverride(newOverride);

        try {
            await API.updateItemField(override.id, 'inventory', override.inventory, identity?.accessToken);
        }
        catch (err: any) {

        }

    }

    const handleEditSupplier = async (value: string) => {
        if (value) {
            if (variant.supplierUrls.length === 0) {
                await API.updateItemField(variant.id, 'supplierUrl', value, identity?.accessToken);
            }
            else if (variant.supplierUrls[0] !== value) {
                await API.updateItemField(variant.id, 'supplierUrl', value, identity?.accessToken);
            }
        }
        setSupplierUrl(value);
    }

    const handleEditDistributor = async (value: string) => {
        setOverride({
            ...override,
            distributorUrl: value
        });
        if (variant.distributorUrl !== override.distributorUrl) {
            await API.updateItemField(variant.id, 'distributorUrl', value, identity?.accessToken);
        }
    }

    const handleEditImage = async (value: string) => {
        if (value.indexOf('thequeensflorist.sirv.') <= 0) {
            const tokens = value.split('?');
            try {
                await API.cloneImage(variant.id, tokens[0], identity?.accessToken);
                setImageUrl(value);
            }
            catch (err: any) {

            }
        }
        // setIsBlocking(true);
    }

    const handleEditHidden = async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setOverride({
            ...override,
            hidden: checked
        });

        await API.updateItemField(variant.id, 'hidden', checked, identity?.accessToken);
    };
    
    const handleEditSpecial = async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setOverride({
            ...override,
            special: checked
        });
        await API.updateItemField(variant.id, 'special', checked, identity?.accessToken);
    };
    
    const handleEditDiscontinued = async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setOverride({
            ...override,
            discontinued: checked
        });
        await API.updateItemField(variant.id, 'discontinued', checked, identity?.accessToken);
    };

    const handleEditDescription = async (value: string) => {
        setOverride({
            ...override,
            description: value
        });

        await API.updateItemField(variant.id, 'description', value, identity?.accessToken);
    }

    const handleEditNotes = async (value: string) => {
        setOverride({
            ...override,
            notes: value
        });

        await API.updateItemField(variant.id, 'notes', value, identity?.accessToken);
    }

    const handleEditSku = async (value: string) => {
        setOverride({
            ...override,
            codes: {
                ...override.codes,
                sku: value
            }
        });
        await API.updateItemField(variant.id, 'sku', value, identity?.accessToken);
    }
    
    const handleEditUpc = async (value: string) => {
        setOverride({
            ...override,
            codes: {
                ...override.codes,
                upc: value
            }
        });
        await API.updateItemField(variant.id, 'upc', value, identity?.accessToken);
    }

    const handleEditVariantName = async (value: string) => {
        const newName = !!parent ? parent.name + " - " + value : value;
        setOverride({
            ...override,
            name: newName,
            group: {
                ...override.group,
                variantName: value
            }
        });
        try {
            await API.updateItemField(variant.id, 'group.variantName', value, identity?.accessToken);
            await API.updateItemField(variant.id, 'name', newName, identity?.accessToken);
        }
        catch (err: any) {
            console.error(err);
        }
    }

    const handleEditCost = async (value: number) => {
        console.log(value);
        setOverride({
            ...override,
            cost: value
        });
        await API.updateItemField(variant.id, 'cost', value, identity?.accessToken);
    }

    const handleEditPrice = async (value: number) => {
        console.log(value);
        setOverride({
            ...override,
            price: value
        });
        await API.updateItemField(variant.id, 'price', value, identity?.accessToken);
    }

    /**
     * We are an editor. Huzzah!
     */
    return (
      <React.Fragment>
        <TableRow>
            <TableCell>
            <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
            >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            </TableCell>
            <TableCell>
                <Link to={`/item/${variant.id}`}>
                    <ItemImage className='thumbnail' id={variant.imageId} title={variant.name} timestamp={variant.updatedAt?.toString()}/>
                </Link>
            </TableCell>
            <TableCell>
                <DoubleClickTextField label="Variant Name" value={override.group?.variantName} onCommit={handleEditVariantName}/>
            </TableCell>
            <TableCell>
                <div><EditablePriceField onCommit={handleEditPrice} label="Price" value={override.price}/></div>
                <div><EditablePriceField onCommit={handleEditCost} label="Cost" value={override.cost}/></div>
            </TableCell>
            <TableCell>
                <DoubleClickTextField label="UPC" value={override.codes?.upc} onCommit={handleEditUpc}/>
                <DoubleClickTextField label="SKU" value={override.codes?.sku} onCommit={handleEditSku}/>
            </TableCell>
            <TableCell>
                <CountInput id="edit-stock" resetAt={variant.inventory["liverpool"]?.resetAt} handleCommit={(value: number) => handleEditStock("liverpool", value)} label="Lvpl" initial={variant.inventory["liverpool"] ? variant.inventory["liverpool"].count : 0}/>
                <CountInput id="edit-stock" resetAt={variant.inventory["bridgewater"]?.resetAt} handleCommit={(value: number) => handleEditStock("bridgewater", value)} label="Bwtr" initial={variant.inventory["bridgewater"] ? variant.inventory["bridgewater"].count : 0}/>
                <CountInput id="edit-stock" resetAt={variant.inventory["warehouse"]?.resetAt} handleCommit={(value: number) => handleEditStock("warehouse", value)} label="Ware" initial={variant.inventory["warehouse"] ? variant.inventory["warehouse"].count : 0}/>
                <CountInput id="edit-stock" resetAt={variant.inventory["bongbus"]?.resetAt} handleCommit={(value: number) => handleEditStock("bongbus", value)} label="Bus" initial={variant.inventory["bongbus"] ? variant.inventory["bongbus"].count : 0}/>
            </TableCell>
            <TableCell>
            <IconButton
                aria-label="menu"
                size="small"
                onClick={() => setMenuOpen(true)}>
                    <MoreVertIcon/>
            </IconButton>
            </TableCell>
        </TableRow>
        <TableRow>
        <TableCell className='variant-table' style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className='variant-subtable' sx={{ margin: 1 }}>
              <Table className='variant-subtable' size="small" aria-label="purchases">
                <TableBody>
                <TableRow>
                      <TableCell component="th" scope="row">
                          <div className="item-editor-text-fields"><DoubleClickTextField id="edit-supplierUrl" label="Supplier Link" value={supplierUrl} onCommit={handleEditSupplier}/></div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <div className="item-editor-text-fields"><DoubleClickTextField id="edit-distributorUrl" label="Manufacturer Link" value={override.distributorUrl} onCommit={handleEditDistributor}/></div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <div className="item-editor-text-fields"><DoubleClickTextField id="edit-imageUrl" label="Image URL (will be moved to our image server)" value={imageUrl} onCommit={handleEditImage}/></div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <div className="variant-checkbox">
                            <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={override.hidden}
                                        onChange={handleEditHidden}
                                        name="checkedHidden"
                                    />
                                    }
                                    label="Hidden"
                                />
                        </div>
                        <div className="variant-checkbox">
                            <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={override.special}
                                        onChange={handleEditSpecial}
                                        name="checkedSpecial"
                                    />
                                    }
                                    label="Special Order"
                                />
                        </div>
                        <div className="variant-checkbox">
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={override.discontinued}
                                    onChange={handleEditDiscontinued}
                                    name="checkedDiscontinued"
                                />
                                }
                                label="Discontinued"
                            />
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <EditableMarkdownField
                            value={override.description}
                            onCommit={handleEditDescription}
                            label="Description"/>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <EditableMarkdownField
                                value={override.notes}
                                onCommit={handleEditNotes}
                                label="Notes"/>
                      </TableCell>
                    </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      </React.Fragment>
    )
}

export default VariantRow
