import IconButton from '@mui/material/IconButton/IconButton';
import React from 'react';
import { useContext } from 'react';
import IdentityContext from '../../auth/IdentityContext';
import MenuIcon from '@mui/icons-material/Menu';

const EditorMenu = React.lazy(() => import("./EditorMenu"));


type Props = {
    deptOpen: boolean,
    setDeptOpen: React.Dispatch<React.SetStateAction<boolean>>
}
  
const HamburgerMenu = ({ deptOpen, setDeptOpen }: Props) => {
    const {identity, setIdentity} = useContext(IdentityContext);

    if (identity?.accessToken && identity?.roles?.includes("admin")) {
        return (
            <EditorMenu deptOpen={deptOpen} setDeptOpen={setDeptOpen}/>
        )
    }

    return (
        <IconButton edge="start" color="inherit"
            aria-label="open drawer"
            onClick={() => setDeptOpen(true)}>
            <MenuIcon />
        </IconButton>
    );
}

export default HamburgerMenu
