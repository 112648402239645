import React, { useEffect, useState, useContext } from 'react';
import API from '../../../API';
import IdentityContext from '../../auth/IdentityContext';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card } from '@mui/material';

import Price from '../../widgets/shop/Price';

const InventoryReport = () => {
  const [items, setItems] = useState<IItem[]>([]);
  const {identity, setIdentity} = useContext(IdentityContext);

  useEffect(() => {
    const doFetchIncompleteItems = async() => {
      const myItems = await API.getItems(identity?.accessToken);
      if (myItems) {
        setItems(myItems);
      }
    }

    doFetchIncompleteItems();
  }, []);

  let totalCost = 0;
  let totalPrice = 0;
  let totalEstimatedCost = 0;
  let totalEstimatedPrice = 0;
  let totalAbsoluteCost = 0;
  let totalAbsolutePrice = 0;
  let missingPrice = 0;
  let missingCost = 0;
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    const tmpCount = Object.keys(item.inventory).reduce((sum, key) => sum + item.inventory[key].count, 0);
    const count = tmpCount > 0 ? tmpCount : 0;

    if (count > 999) {
      continue; // Hack to ignore other hacks about flower counts for now
    }
  if (!item.price || item.price < 0) {
      missingPrice += 1;
      if (item.cost) {
        totalCost += item.cost * count;
      }
    }
    else {
      const price = item.price;
      const specifiedCost = (!!item.cost && item.cost > 0) ? item.cost : 0;
      const cost = specifiedCost ? specifiedCost : Math.trunc(price / 2); // Fall back to 100% profit
      const costEstimated = specifiedCost ? false : true;
      totalCost += cost * count;
      totalPrice += price * count;
      if (costEstimated) {
        totalEstimatedCost += cost * count;
        totalEstimatedPrice += price * count;
      }
      else {
        totalAbsoluteCost += cost * count;
        totalAbsolutePrice += price * count;
      }
    }
  }

  return (
    <div className="ags-main">
    <div className="ags-body">
    <Card>
      <TableContainer component={Paper}>
      <Table size="small" aria-label="collapsible table" className='variant-table'>
        <TableHead>
        <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Cost</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Count</TableCell>
              <TableCell>Total Cost</TableCell>
              <TableCell>Total Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Unpriced Items</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>{missingPrice}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Estimate</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell><Price price={totalEstimatedCost}/></TableCell>
            <TableCell><Price price={totalEstimatedPrice}/></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Absolute</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell><Price price={totalAbsoluteCost}/></TableCell>
            <TableCell><Price price={totalAbsolutePrice}/></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Total</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell><Price price={totalCost}/></TableCell>
            <TableCell><Price price={totalPrice}/></TableCell>
          </TableRow>
          {items.map((item: IItem) => {
            const tmpCount = Object.keys(item.inventory).reduce((sum, key) => sum + item.inventory[key].count, 0);
            const count = tmpCount > 0 ? tmpCount : 0;
            const price = (!!item.price && item.price > 0) ? item.price : 0;
            const specifiedCost = (!!item.cost && item.cost > 0) ? item.cost : 0;
            const cost = specifiedCost ? specifiedCost : Math.trunc(price / 2); // Fall back to 100% profit
            const costEstimated = item.cost ? false : true;
            const myCost = cost * count;
            const myPrice = price * count;
            
            return (
              <TableRow>
                <TableCell>
                <a href={`${process.env.REACT_APP_AGS_ADMIN}/admin/${API.getStore()}/item/${item.id}`} target="_blank" rel="noreferrer">
                  {item.name}
                </a>
                </TableCell>
                <TableCell><Price price={specifiedCost} showZeroAsBlank={true}/></TableCell>
                <TableCell><Price price={price} showZeroAsBlank={true}/></TableCell>
                <TableCell>{count}</TableCell>
                <TableCell><Price price={myCost} showZeroAsBlank={true}/></TableCell>
                <TableCell><Price price={myPrice} showZeroAsBlank={true}/></TableCell>
              </TableRow>
          )})}
        </TableBody>
      </Table>
      </TableContainer>
    </Card>
    </div>
    </div>
  )
}

export default InventoryReport