import axios from "axios"
import AbstractCommonApi from './AbstractCommonClient';

class Shipping extends AbstractCommonApi {

  getRawShippingEstimate = async (province: string, postal: string, value: number): Promise<ShippingDetails[]> => {
    var formData: Record<string, any> = {};
    formData['province'] = province;
    formData['postal'] = postal;
    formData['value'] = value;

    try {
        const results = await axios.post(`${this.baseUrl}/api/v1/shipping/estimate`, formData);
        return results.data;
    } catch (error: any) {
      console.log(error);
        throw new Error(error);
    }
  }
}

export default new Shipping();
