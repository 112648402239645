import { SelectChangeEvent, Typography } from '@mui/material';
import InvoiceReviewItems from './InvoiceReviewItems';
import InvoiceReviewTotals from './InvoiceReviewTotals';
import ReviewDeliveryAddress from './ReviewDeliveryAddress';
import ReviewCustomerContact from './ReviewCustomerContact';
import InvoiceReviewDelivery from './InvoiceReviewDelivery';
import ReviewState from './ReviewState';
import Alert from '@mui/material/Alert';
import IfAuthorized from '../../conditions/IfAuthorized';
import ReviewBillingAddress from './ReviewBillingAddress';
import IfTrue from '../../conditions/IfTrue';

type Props = {
  order: IOrder,
  handleStateChange?: (event: SelectChangeEvent<{ value: unknown }>) => void
}

const InvoiceReviewPanel = ({ order, handleStateChange }: Props) => {

  return (
    <>
      <Typography variant="h5" component="h2">Order/Invoice {order.id} summary</Typography>
      <Alert severity="info">
        Order state: {order.state}<br/>Payment method: {order.paymentMethod}
        <IfTrue condition={order.state === "OPEN"}>
          <p/>
          <div>Please e-transfer total amount to billing@atlanticgrowshop.ca</div>
          <div>or arrange payment at (902) 343-3569.</div>
        </IfTrue>
        <IfTrue condition={order.state === "CARD_INCOMPLETE"}>
          <p/>
          <div className='error-text'>Card payment was incomplete.</div>
        </IfTrue>
      </Alert>
      <ReviewState state={order.state} handleStateChange={handleStateChange}/>
      <InvoiceReviewItems order={order}/>
      <InvoiceReviewTotals order={order}/>
      <InvoiceReviewDelivery order={order}/>
      <IfAuthorized role='editor'>
        <Alert className='note-info' severity="info"><div>Delivery Notes</div>{order.notes}</Alert>
        <Alert className='note-info' severity="info"><div>Card</div>{order.card}</Alert>
        <ReviewDeliveryAddress addr={order.deliveryAddress}/>
        <ReviewCustomerContact cust={order.customer}/>
        <ReviewBillingAddress cust={order.cardholderAddress}/>
      </IfAuthorized>

      {/* <pre>
        {JSON.stringify(order, null, 1)}
      </pre> */}
        {/* <Typography variant="h5" component="h2">Order Summary</Typography>
        <ModifyCartPanel readonly={true}/>
        <TotalsPanel />
        <ContactPanel />
        <ShippingPanel />
        <ShippingMethodPanel />
        <PaymentPanel /> */}
    </>
  );
}

export default InvoiceReviewPanel