import { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import API from '../../API';
import Brand from '../widgets/brands/Brand';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ItemImage from '../widgets/shop/ItemImage';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ReactMarkdown from 'react-markdown'
import ItemNotes from '../widgets/details/ItemNotes';
import PriceSummary from '../widgets/shop/PriceSummary';
import IfFalse from '../conditions/IfFalse';
import IfTrue from '../conditions/IfTrue';
import VariantSelector from '../widgets/details/VariantSelector';
import Price from '../widgets/shop/Price';
import { Alert, CardActions, IconButton } from '@mui/material';
import BreadCrumbs from '../widgets/BreadCrumbs';
import IdentityContext from '../auth/IdentityContext';
import DeliveryNotes from '../widgets/details/DeliveryNotes';
import Snackbar from '@mui/material/Snackbar';

const ItemDetailsPage = () => {
  const {identity, setIdentity} = useContext(IdentityContext);
  let { itemId } = useParams<{itemId: string}>();
  const [item, setItem] = useState<IItem>({
      id: '',
      name: '',
      description: '',
      imageId: '',
      categoryId: '',
      supplierUrls: [],
      homeUrls: [],
      externalId: {},
      inventory: {}
  });

  const [reloadPage, setReloadPage] = React.useState<boolean>(true);
  const [selectedVariation, setSelectedVariation] = React.useState<IItem>(item);
  const [variants, setVariants] = useState<IItem[]>([selectedVariation]);
  
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);


  useEffect(() => {
    const fetchItem = async () => {
      try {
        const item = await API.getItemById(itemId, identity?.accessToken);
        setItem(item);
        setSelectedVariation(item);

        if (item.group?.enabled && !item.group.variantName) {
          // Download the information about the variants as well.
          setVariants(await API.getVariantsById(itemId));
        }
        else {
          // This is its own variant
          setVariants([item]);
        }
      }
      catch (err: any) {
        setItem({
          id: 'ERROR',
          name: err.message,
          categoryId: '',
          supplierUrls: [],
          homeUrls: [],
          externalId: {},
          inventory: {}
        });
        console.error(err);
      }
    }
    if (itemId) {
      setItem({
        id: '',
        name: '',
        description: '',
        imageId: '',
        categoryId: '',
        supplierUrls: [],
        homeUrls: [],
        externalId: {},
        inventory: {}
    });
      fetchItem();
    }
  }, [itemId, reloadPage]);

  if (!itemId) {
    window.document.body.style.overflow = 'auto';
    return (
      <>
      </>
    )
  }

  const selectedImageId = selectedVariation.imageId ? selectedVariation.imageId : item.imageId;

  window.document.body.style.overflow = 'hidden';

  if (!item.id) {
    return (
      <div id="id-main">
        Loading item: {itemId}
      </div>
    )
  }

  if ("ERROR" === item.id) {
    return (
      <div id="id-main">
        Error loading item: {item.name}
      </div>
    )
  }

  const goBack = () => {
    console.log("HANDLE BACK");
    window.document.body.style.overflow = 'auto';
    if (location.state && (location.state as any).from) {
      history.goBack();
    }
    else {
      history.push('/');
      history.go(0);
    }
  }

  const handleClose = (reason?: string) => {
    console.log("HANDLE CLOSE");
    window.document.body.style.overflow = 'auto';
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const stockCounter = Object.keys(item.inventory).reduce((sum, key) => sum + item.inventory[key].count, 0);

  return (
    <>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={6000} onClose={(event?: any, reason?: string) => {handleClose(reason)}}>
        <Alert onClose={(event?: any, reason?: string) => {handleClose(reason)}} severity="success">
          Added {item.name} to cart.
        </Alert>
      </Snackbar>
      <div className="overlay" onClick={goBack}></div>
      <div id="id-main">
        <div className="header-bar">
          <BreadCrumbs item={item} variant={selectedVariation}/>
          <div className="spacer"></div>
          <IconButton
            className="close-icon"
            aria-label="back"
            onClick={goBack}
            size="large">
            <CloseIcon />
          </IconButton>
        </div>
        <div className="dialog-body">
          <div className='hlayout'>
            <Brand name={item.brand ? item.brand : ''} />
          </div>
          <div className='id-title'>{item.name}</div>
          <div className="id-content">
            <Card className='id-image'>
              <CardContent>
              <ItemImage lazy={false} width={400} id={selectedImageId} title={item.name} timestamp={item.updatedAt?.toString()}/>
              </CardContent>
            </Card>
            <div className='id-text-content'>
              <div>
              <ReactMarkdown>{selectedVariation.description ? selectedVariation.description : (item.description ? item.description : '')}</ReactMarkdown>
              </div>
              <div>
                <div>
                  <IfTrue condition={item.group?.enabled && item.id === selectedVariation.id}>
                    <PriceSummary variations={variants}/>
                  </IfTrue>
                  <IfFalse condition={item.group?.enabled && item.id === selectedVariation.id}>
                    <Price variation={selectedVariation}/>

                    <DeliveryNotes stock={stockCounter} specialDelivery={!!item.special}/>
                  </IfFalse>
                </div>
              </div>
              <ItemNotes notes={selectedVariation.notes ? selectedVariation.notes : (item.notes ? item.notes : '')}/>
              <div>
                <input type="hidden" id="purchase-variation" name="variation" value={selectedVariation.id}></input>
              </div>
              <div>
                <IfTrue condition={item.group?.enabled && !item.group.variantName}>
                  <VariantSelector item={item} variants={variants} setSelectedVariation={setSelectedVariation}/>
                </IfTrue>
              </div>
              <IfTrue condition={item.discontinued}>
                <Alert severity="error">
                  <div className='discontinued-text'>This item has been discontinued: {stockCounter} left in stock</div>
                </Alert>
              </IfTrue>
            </div>
          </div>
          {/* <pre>
            {JSON.stringify(item, null, 1)}
          </pre> */}
        </div>
      </div>
    </>
  );
}

export default ItemDetailsPage