import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ConfigContext from '../../../contexts/ConfigContext';
import { useHistory } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';
import CategoryContext, { CategoryState } from '../../../contexts/CategoryContext';

type Props = {
    deptOpen: boolean,
    setDeptOpen: React.Dispatch<React.SetStateAction<boolean>>
  }

const CategoryDrawer = ({ deptOpen, setDeptOpen }: Props) => {
    const {config} = React.useContext(ConfigContext);
    const { categories } = React.useContext(CategoryContext);
    const root = config.general.root_category;

    const history = useHistory();

    const gotoCategory = (category: ICategory) => {
        console.log(`history.push(${category.url})`);
        history.push(category.url);
    }

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
            ) {
            return;
        }

        setDeptOpen(open);
    };
    

    const anchor = 'left';

  return (
    <div>
        <Drawer
        className='department-drawer'
        anchor='left'
        open={deptOpen}
        onClose={toggleDrawer(false)}
        >
            <Box
                className='department-box'
                sx={{ width: 250 }}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}>

                <div>
                <IconButton onClick={() => toggleDrawer(false)} size="large">
                    <ChevronLeftIcon />
                </IconButton>
                </div>

                <div className='department-header'>Departments</div>
                <List>
                {categories?.map((category: ICategory) => (
                    <ListItem button className='dept' key={category.shortName} onClick={() => {gotoCategory(category)}}>
                        {/* <ListItemIcon>
                            {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                        </ListItemIcon> */}
                        <ListItemText primary={category.shortName} />
                    </ListItem>
                ))}
                </List>
            </Box>
        </Drawer>
    </div>
    );
}

export default CategoryDrawer