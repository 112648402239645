import React from 'react';
import ReactDOM from 'react-dom';

// Legacy CSS
import './css/fonts.css';
import './css/index.css';
import './css/departments.css';
import './css/store.css';
import './css/card.css';
import './css/categories.css';
import './css/quickbar.css';
import './css/menubar.css';
import './css/footer.css';
import './css/itemdetails.css';

// Default CSS
import './css/default/header.css';
import './css/default/menu.css';
import './css/default/categories.css';
import './css/default/itemdetails.css';
import './css/default/ageGate.css';

// Tablet CSS
import './css/tablet/header-1024.css';
import './css/tablet/menu-1024.css';
import './css/tablet/menu-800.css';
import './css/tablet/categories-800.css';
import './css/tablet/itemdetails-1024.css';

// Mobile CSS
import './css/mobile/header-640.css';
import './css/mobile/header-480.css';
import './css/mobile/menu-640.css';
import './css/mobile/categories-640.css';
import './css/mobile/categories-480.css';


import App from './App';
import reportWebVitals from './reportWebVitals';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./components/auth/AuthUtils";

const msalInstance = new PublicClientApplication(msalConfig);

// import './css/thebackroom.css';
// import './css/atlanticgrowshop.css';
// import './css/thequeensflorist.css';

  ReactDOM.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
