import { IconButton, TextField } from '@mui/material';
import React, {useContext, useEffect, useLayoutEffect, useRef} from 'react';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Edit';
import IfTrue from '../../conditions/IfTrue';
import IfFalse from '../../conditions/IfFalse';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import FileUpload from 'react-mui-fileuploader'
import IdentityContext from '../../auth/IdentityContext';
import API from '../../../API';

type Props = {
    value?: string,
    onCommit: (value: string) => void,
    readonly?: boolean,
    label?: string,
    id?: string,
    itemId?: string,
    layout?: string,
    fileInput?: boolean
}

const DoubleClickTextField = ({ value, itemId, fileInput, onCommit, readonly, label, id, layout }: Props) => {
    const [editing, setEditing] = React.useState<boolean>(false);
    const [textValue, setTextValue] = React.useState<string>(value ? value : '');
    const {identity, setIdentity} = useContext(IdentityContext);

    const searchInput = useRef<HTMLInputElement>(null)

    useEffect(() => {
        setTextValue(value ? value : '');
    }, [value]);

    useLayoutEffect(() => {
        if (searchInput?.current) {
            searchInput.current.focus();
        }
    });
      
    const onChange = (event: React.FocusEvent<HTMLInputElement>) => {
        const stringValue = event.target.value;
        setTextValue(stringValue);
    }

    const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const stringValue = event.target.value.trim();
        setEditing(false);
        setTextValue(stringValue);        
        onCommit(stringValue);
    }

    const onClick = () => {
        setEditing(true);
    }

    const handleFileUploadError = (error: any) => {
        // Do something...
        console.log("ERROR: " + error);
    }
    
    const handleFilesChange = async (files: any) => {
        // Do something...
        try {
            if (itemId && files.length > 0) {
                const imageId = await API.uploadImage(itemId, files[0], identity?.accessToken);
                setEditing(false);
                setTextValue(files[0].name);        
            }
        }
        catch (error: any) {
            console.log("ERROR: " + error);
        }
    }

    if (editing) {
        return (
        <>
            <div className='dctf-input'>
                <TextField
                    inputRef={searchInput}
                    className='dctf-textfield'
                    InputLabelProps={{ shrink: true }}
                    id={id} label={label}
                    value={textValue} onChange={onChange} onBlur={onBlur}/>
            </div>
        </>
        );
    }

    if (textValue.trim() === '') {
        return <>
        <div className='dctf-label'>
            {label}
        </div>
        <div className='dctf-disabled-text'>
            <IconButton aria-label="Edit" size="small" onClick={onClick}>
                <EditIcon fontSize="small" />
            </IconButton>
            Set {label}
        </div>
        </>;
    }

    return (
        <>
        <div className='dctf-label'>
            {label}
        </div>
        <IfTrue condition={fileInput}>
        <FileUpload
            multiFile={false}
            disabled={false}
            title="Upload new image"
            header="[Drag to drop]"
            leftLabel="or"
            rightLabel="to select files"
            buttonLabel="click here"
            maxFileSize={10}
            maxUploadFiles={0}
            maxFilesContainerHeight={100}
            errorSizeMessage={'fill it or move it to use the default error message'}
            allowedExtensions={['jpg', 'jpeg']}
            onFilesChange={handleFilesChange}
            onError={handleFileUploadError}
            // imageSrc={'path/to/custom/image'}
            bannerProps={{ elevation: 0, variant: "outlined" }}
            containerProps={{ elevation: 0, variant: "outlined" }}
            />
        </IfTrue>
        <div className='dctf-text'>
            <IconButton aria-label="Edit" size="small" onClick={onClick}>
                <EditIcon fontSize="small" />
            </IconButton>
            <IfTrue condition={!!textValue && textValue.startsWith('http')}>
                <a href={textValue} target="_blank" rel="noreferrer">{textValue}</a>
            </IfTrue>
            <IfFalse condition={!!textValue && textValue.startsWith('http')}>
                {textValue}
            </IfFalse>
        </div>
        </>
    )
}

export default DoubleClickTextField