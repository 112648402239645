import React, { useContext } from 'react';
import ItemImage from './ItemImage'
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link, useHistory, useLocation } from "react-router-dom";
import Price from './Price';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useDrag, useDrop } from 'react-dnd'
import { DnDTypes } from "../dnd/DnDTypes";
import IdentityContext from '../../auth/IdentityContext';
import AlertDialog from '../../dialogs/AlertDialog';
import API from '../../../API';
import IfTrue from '../../conditions/IfTrue';
import IfFalse from '../../conditions/IfFalse';
import IfAuthorized from '../../conditions/IfAuthorized';
import PriceRange from './PriceRange';

type Props = ItemProps & {
}

const Item = ({ item }: Props) => {
  const {identity, setIdentity} = useContext(IdentityContext);

  const [open, setOpen] = React.useState(false);
  const [display, setDisplay] = React.useState<undefined | string>('flex');
  const history = useHistory();
  const location = useLocation();

  // Get the cart from the context
  const [movingItem, setMovingItem] = React.useState<undefined | ICategory >(undefined);

  const useStyles = makeStyles({
    root: {
      width: 200,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });
  const classes = useStyles();
  
  const [{ isDragging }, drag] = useDrag(() => ({
    type: DnDTypes.ITEM,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    end: async (droppedItem, monitor) => {
      const dropResult = monitor.getDropResult<ICategory>()
      if (item && dropResult) {
        if (identity?.roles?.includes('editor')) {
          setMovingItem(dropResult);
          // await updateItemField(
          //   item.id,
          //   'categoryId',
          //   dropResult.id,
          //   identity.accessToken);
          // setVisibility('hidden');
        }
      }
    },
  }));

  const handleClose = (reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const onItemMove = async () => {
    if (movingItem && identity) {
      console.log(`You dropped ${item.name} into ${movingItem.name}!`);
      await API.updateItemField(item.id, 'categoryId', movingItem.id, identity.accessToken);
      setMovingItem(undefined);
      setDisplay('none');
    }
  }

  let backgroundColor = isDragging ? 'lightGrey' : 'white';
  const hiddenClass = item.hidden ? 'hidden' : '';
  const newCategoryName = movingItem ? movingItem.name : '';


  const isGroup = item?.group?.enabled && !item?.group?.variantName;
  const stockCounter = isGroup ?
    (item.group?.variantSummary?.stock) :
    Object.keys(item.inventory).reduce((sum, key) => sum + item.inventory[key].count, 0);
  
  const cardStyle: React.CSSProperties = display ? {
      backgroundColor: backgroundColor,
      display: display
    } : {
      backgroundColor: backgroundColor
    };
  

  if (isGroup) {
    const priceHigh = item.group?.variantSummary?.priceHigh;
    const priceLow = item.group?.variantSummary?.priceLow;
    
    return (
      <>
      <AlertDialog
        initialState={movingItem !== undefined}
        title="Move item to new category"
        text={`Move ${item.name} into ${newCategoryName}?`}
        onYes={onItemMove}
        onNo={() => {setMovingItem(undefined);}}/>
      <Card ref={drag} style={cardStyle} className={classes.root + ' ' + hiddenClass + ' group-item'}>
        <div className='group-header'>Group</div>
        <a href={`${process.env.REACT_APP_AGS_ADMIN}/admin/${API.getStore()}/item/${item.id}`} className='card-link' target="_blank" rel="noreferrer">
          <CardContent className='card'>
            <IfTrue condition={item?.discontinued}>
              <div className='discontinued-banner'>Discontinued</div>
            </IfTrue>
            <ItemImage id={item.imageId} height={180} title={item.name} timestamp={item.updatedAt}/>
            <div className={classes.title + ' card-title'} color="textPrimary">
              <div className='brand-name'>{item.brand}</div>
              <div className='item-name'>{item.name}</div>
            </div>
            <div>
              <PriceRange priceLow={priceLow as number} priceHigh={priceHigh as number}/>
            </div>
            <IfAuthorized role='editor'>
              <IfFalse condition={item.discontinued}>
                <div className='stock-count space-above'>{stockCounter} in stock</div>
              </IfFalse>
            </IfAuthorized>
            <IfTrue condition={item.discontinued}>
              <div className='stock-count space-above'>{stockCounter} left in stock</div>
            </IfTrue>
          </CardContent>
        </a>
      </Card>
      </>
    )

  }

  return (
    <>
    <AlertDialog
      initialState={movingItem !== undefined}
      title="Move item to new category"
      text={`Move ${item.name} into ${newCategoryName}?`}
      onYes={onItemMove}
      onNo={() => {setMovingItem(undefined);}}/>
    <Card ref={drag} style={cardStyle} className={classes.root + ' ' + hiddenClass}>
      <Snackbar open={open} autoHideDuration={6000} onClose={(event?: any, reason?: string) => {handleClose(reason)}}>
        <Alert onClose={(event?: any, reason?: string) => {handleClose(reason)}} severity="success">
          Added {item.name} to cart.
        </Alert>
      </Snackbar>
      <a href={`${process.env.REACT_APP_AGS_ADMIN}/admin/${API.getStore()}/item/${item.id}`} className='card-link' target="_blank" rel="noreferrer">
        <CardContent className='card'>
          <IfTrue condition={item?.discontinued}>
            <div className='discontinued-banner'>Discontinued</div>
          </IfTrue>
            <ItemImage id={item.imageId} height={180} title={item.name} timestamp={item.updatedAt}/>
            <div className={classes.title + ' card-title'} color="textPrimary">
              <div className='brand-name'>{item.brand}</div>
              <div className='item-name'>{item.name}</div>
            </div>
            <div>
              <Price price={item.price}/>
            </div>
            <IfAuthorized role='editor'>
              <IfFalse condition={item.discontinued}>
                <div className='stock-count space-above'>{stockCounter} in stock</div>
              </IfFalse>
            </IfAuthorized>
            <IfTrue condition={item.discontinued}>
              <div className='stock-count space-above'>{stockCounter} left in stock</div>
            </IfTrue>

        </CardContent>
      </a>
    </Card>
    </>
  )
}

export default Item