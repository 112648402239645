import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import API from '../../../API';

type Props = {
    value?: string,
    onChange: (categoryId?: string) => void
}

const CategorySelector = ({ value, onChange }: Props) => {
    const defaultCategory: ICategory = {id: '0', parentId: '1', path: '', name: 'Select a category', shortName: 'Select a category', children: [], url: ''};

    const [categories, setCategories] = useState<ICategory[]>([defaultCategory])
    const [selected, setSelected] = useState<ICategory | null>(defaultCategory)
    const [inputValue, setInputValue] = React.useState(defaultCategory.id);

    useEffect(() => {
        const fetchCategoriesR = async (categories: ICategory[], path?: string): Promise<ICategory[]> => {
            let results: ICategory[] = [];
            for (let i = 0; i < categories.length; i++) {
                const category = categories[i];
                const newPath = path ? path + " > " + category.name : category.name;
                category.path = newPath;
                if (category.children && category.children.length > 0) {
                    const children = await fetchCategoriesR(category.children, newPath);
                    results = results.concat(children);
                }
                else {
                    results.push(category);
                }
            }
            return results;
        }

        const fetchCategories = async () => {
            try {
                const allRoots = await API.getCategories();
                let categories = await fetchCategoriesR(allRoots); // allRoots.filter(applyCategoryFilter);

                // Add the default category
                categories.unshift(defaultCategory)

                setCategories(categories);
            }
            catch (err: any) {
                console.error(err.message);
            }
        }
        
        fetchCategories();
    }, [])

    useEffect(() => {
        if (categories.length > 0) {
            let selectedCategory = categories[0];
            const myCategories = categories.filter(category => category.id === value);
            if (myCategories.length > 0) {
                selectedCategory = myCategories[0];
            }
            setSelected(selectedCategory);
            setInputValue(selectedCategory.name);
        }
    }, [value, categories])

    const onCategoryChanged = () => {
        onChange(selected?.id);
    }
        
    return (
        <Autocomplete
          id="category-selector"
          value={selected}
          inputValue={inputValue}
          onChange={(event: any, value: ICategory | null) => {setSelected(value);}}
          onInputChange={(event, newInputValue) => {setInputValue(newInputValue);}}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          options={categories}
          getOptionLabel={(category) => category.path}
          renderInput={(params) => <TextField {...params} label="Category" variant="outlined" />}
          onBlur={onCategoryChanged}
          getOptionDisabled={(option) => option.id === '0'}
        />
    );
}

export default CategorySelector