import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import IdentityContext from '../auth/IdentityContext';
import Button from '@mui/material/Button';
import API from '../../API';
import { Snackbar, TableBody } from '@mui/material';
import ConfigContext from '../../contexts/ConfigContext';
import { Table } from 'react-bootstrap';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import VariantRow from './VariantRow';
import AddIcon from '@mui/icons-material/Add';

type Props = {
    reloadPage?: boolean;
    setReloadPage?: React.Dispatch<React.SetStateAction<boolean>>;
    item: IItem;
    setOnSavePage?: Dispatch<SetStateAction<() => void>>;
}

const VariantEditor = ({reloadPage, setReloadPage, item, setOnSavePage}: Props) => {
    const {config} = useContext(ConfigContext);
    const root = config.general.root_category;
    const {identity, setIdentity} = useContext(IdentityContext);
    
    const [variants, setVariants] = useState<IItem[]>([]);
    const [newVariantToggle, setNewVariantToggle] = useState(false);

    useEffect(() => {
        const fetchItem = async () => {
          try {
              setVariants(await API.getVariantsById(item.id, identity?.accessToken));
          }
          catch (err: any) {
            console.error(err);
          }
        }
        if (item) {
          fetchItem();
        }
    }, [identity?.accessToken, item, newVariantToggle]);

    const createNewVariant = async () => {
      const newVariant = {
          id: '',
          name: item.name ? item.name + '-unnamed': 'unnamed',
          categoryId: item.categoryId ? item.categoryId : '',
          brand: item.brand ? item.brand : '',
          supplierUrls: [],
          homeUrls: [],
          externalId: {},
          inventory: {},
          group: {
              enabled: true,
              groupId: item.id,
              variantName: 'unnamed'
          }
      };
      try {
        await API.createItem(newVariant, identity?.accessToken);
        // Kick off refresh
        setNewVariantToggle(!newVariantToggle);
      }
      catch (error: any) {
        console.error(error);
      }
    }
    
    /**
     * We are an editor. Huzzah!
     */
    return (
      <>
        <div>
          Variant Editor
          <Button className='add-variant' variant="outlined" startIcon={<AddIcon />} onClick={createNewVariant}>
            Add Variant
          </Button>
        </div>

        <Table className='variant-table'>
            <TableHead>
                <TableRow>
                    <TableCell className='narrow-cell'></TableCell>
                    <TableCell className='thumbnail-cell'></TableCell>
                    <TableCell>Variant</TableCell>
                    <TableCell className='price-cell'>Price/Cost</TableCell>
                    <TableCell>UPC/SKU</TableCell>
                    <TableCell>Stock By Location</TableCell>
                    <TableCell className='narrow-cell'></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>

                {variants.map((variant, idx) => {
                    return (
                        <VariantRow variant={variant} parent={item}/>
                    );
                })}

            </TableBody>
        </Table>
      </>
    )
}

export default VariantEditor
