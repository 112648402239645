import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import InvoicedItem from './InvoicedItem';

const useStyles = makeStyles({
  table: {
  },
});

type Props = {
  order: IOrder
}

const InvoiceReviewItems = ({ order }: Props) => {
  const classes = useStyles();

  return (
    <div className="cart-parent">
      <div className="cart-main">
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>Item</TableCell>
                <TableCell align="center">Quantity</TableCell>
                <TableCell align="right">Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order.items.map((item, index) => (
                <InvoicedItem key={index} item={item}/>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default InvoiceReviewItems