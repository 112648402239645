import React, { useContext, useEffect, useState } from 'react';
import IdentityContext, { IdentityState } from '../auth/IdentityContext';

 type Props = {
  children: React.ReactNode;
  role: string,
}

/**
 * Is the user authorized to see this content? The "any" role still ensures the user is authenticated.
 */
const IfAuthorized = ({ role, children }: Props) => {
    const {identity, setIdentity} = useContext(IdentityContext);

    if (!identity?.accessToken) {
      return (
        <>
        </>
      )
    }

    if (role !== 'any' && !identity?.roles?.includes(role)) {
      return (
          <>
          </>
        )
    }
  
    return (
        <>
            {children}
        </>
    )
}

export default IfAuthorized
