import React, { useEffect, useState, useContext } from 'react';
import API from '../../API';
import IdentityContext from '../auth/IdentityContext';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card } from '@mui/material';
import TransactionRow from '../widgets/transactions/TransactionRow';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Price from '../widgets/shop/Price';
import VariantRow from '../panels/VariantRow';
import SimpleItemRow from '../panels/SimpleItemRow';

const IncompletePage = () => {
  const [items, setItems] = useState<IItem[]>([]);
  const {identity, setIdentity} = useContext(IdentityContext);

  useEffect(() => {
    const doFetchIncompleteItems = async() => {
      const myItems = await API.getIncompleteItems(identity?.accessToken);
      if (myItems) {
        setItems(myItems);
      }
    }

    doFetchIncompleteItems();
  }, []);

  return (
    <div className="ags-main">
    <div className="ags-body">
    <Card>
      <TableContainer component={Paper}>
      <Table size="small" aria-label="collapsible table" className='variant-table'>
        <TableHead>
        <TableRow>
              <TableCell className='thumbnail-cell'></TableCell>
              <TableCell>Variant</TableCell>
              <TableCell className='price-cell'>Price/Cost</TableCell>
              <TableCell>UPC/SKU</TableCell>
              <TableCell>Stock By Location</TableCell>
              <TableCell className='narrow-cell'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item: IItem) => (
            <SimpleItemRow variant={item}/>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
    </Card>
    </div>
    </div>
  )
}

export default IncompletePage