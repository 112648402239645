import { useContext } from 'react';
import CategoryContext from '../../../contexts/CategoryContext';
import { useHistory } from "react-router-dom";
import ItemImage from './ItemImage';

type Props = {
    categoryId: string
}
  
const SubcategoryPanel = ({ categoryId }: Props) => {
    const history = useHistory();
    const { category } = useContext(CategoryContext);

    const gotoCategory = (category: ICategory) => {
        console.log(`history.push(${category.url})`); 
        history.push(category.url);
    }

    /**
     * Look through the list of categories to try and find the one with the supplied name.
     */
    const getCategoryByName = (parent: any, nameOrId: string): ICategory | undefined => {
        const children = parent.categories ? parent.categories : parent.children;
        for (let i = 0; i < children.length; i++) {
            const child: ICategory = children[i];
            if (child.shortName.endsWith(nameOrId) || child.id === nameOrId) {
                return child;
            }
            const result = getCategoryByName(child, nameOrId);
            if (result) {
                return result;
            }
        }
        return undefined;
    }

    let parentCategory = category;
    if (categoryId) {
        parentCategory = getCategoryByName(category, categoryId);
        if (!parentCategory) {
            parentCategory = category
        }
    }
    const children = parentCategory.categories ? parentCategory.categories : parentCategory.children;

    const getImagesById = (cat: ICategory): string | undefined => {
        if (cat.imageId) {
            return cat.imageId;
        }

        const children = cat.children;
        if (children && children.length > 0) {
            return getImagesById(children[0]);
        }

        return undefined;
    }
      
    return <>
        <div className='category-panel'>
            {children.map((child: ICategory) => (
                <div key={child.id} className='category-button' onClick={() => gotoCategory(child)}>
                    <label className='category-name'>{child.shortName}</label>
                    <div className='category-icon'>
                        <ItemImage width={180} height={180} id={getImagesById(child)} title={child.name}/>
                    </div>
                </div>
            ))}
        </div>
    </>;
}

export default SubcategoryPanel