import { useState } from 'react';
import { Link } from 'react-router-dom';
import React from 'react';
import CategoryContext from '../../contexts/CategoryContext';

type Props = {
  item?: IItem,
  variant?: IItem,
  categoryId?: string,
}

const BreadCrumbs = ({ item, variant, categoryId }: Props) => {

  const { categories } = React.useContext(CategoryContext);
  const [categoryPath, setCategoryPath] = useState<ICategory[]>([]);


  
  const doGetCategoryPath = (id: string, category: ICategory): ICategory[] => {
    if (category.id === id) {
      return [category];
    }
    if (category.name === id) {
      return [category];
    }
    if (category.children) {
      for (let i = 0; i < category.children.length; i++) {
        const child = category.children[i];
        const path = doGetCategoryPath(id, child);
        if (path.length > 0) {
          return [category, ...path];
        }
      }
    }
    return [];
  }

  if (categories) {
    const cid = categoryId ? categoryId : item?.id;
    if (cid) {
      for (let i = 0; i < categories.length; i++) {
        const path = doGetCategoryPath(cid, categories[i]);
        if (path.length > 0) {
          setCategoryPath(path);
        }
      }
    }
  }

  if (categoryId && categoryPath?.length > 0) {
    const myPath = [...categoryPath];
    const lastCat = myPath.pop();
    return (
      <div className='breadcrumb'>
        &nbsp;&nbsp; <span><Link to={`/category/`}>Categories</Link></span>
        &nbsp;&nbsp; &gt;
        {
          myPath.map(category => {
            const cid = category?.id ? category?.id.replace('#', '') : '';
            return (
            <>
              &nbsp;&nbsp; <span><Link key={category.id} to={`/category/${cid}`}>{category?.shortName}</Link></span>
              &nbsp;&nbsp; &gt;
            </>
          )})
        }
        &nbsp;&nbsp; <span>{lastCat?.shortName}</span>
      </div>
    )
  }

  if (item && variant && item.id !== variant.id) {
    return (
      <div className='breadcrumb'>
        &nbsp;&nbsp; <span><Link to={`/category/`}>Categories</Link></span>
        &nbsp;&nbsp; &gt;
        {
          categoryPath.map(category => {
            const cid = category?.id ? category?.id.replace('#', '') : '';
            return (
            <>
              &nbsp;&nbsp; <span><Link key={category.id} to={`/category/${cid}`}>{category?.shortName}</Link></span>
              &nbsp;&nbsp; &gt;
            </>
          )})
        }
        &nbsp;&nbsp; <span><Link to={`/item/${item.id}`} onClick={() => window.location.reload()}>{item.name}</Link></span>
        &nbsp;&nbsp; &gt;
        &nbsp;&nbsp; <span>{variant.group?.variantName ? variant.group.variantName : variant.name}</span>
      </div>
    )
  }

  if (item) {
    return (
      <div className='breadcrumb'>
        &nbsp;&nbsp; <span><Link to={`/category/`}>Categories</Link></span>
        &nbsp;&nbsp; &gt;
        {
          categoryPath.map(category => {
            const cid = category?.id ? category?.id.replace('#', '') : '';
            return (
            <>
              &nbsp;&nbsp; <span><Link key={category.id} to={`/category/${cid}`}>{category?.shortName}</Link></span>
              &nbsp;&nbsp; &gt;
            </>
          )})
        }
        &nbsp;&nbsp; <span>{item.name}</span>
      </div>
    )
  }

  return (
    <>
    </>
)
}

export default BreadCrumbs