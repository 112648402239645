import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Prompt, useLocation } from 'react-router-dom';
import IdentityContext from '../auth/IdentityContext';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CountInput from '../widgets/inputs/CountInput';
import API from '../../API';
import { useHistory } from "react-router-dom";
import PriceInput from '../widgets/inputs/PriceInput';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ProgressOverlay from '../overlays/ProgressOverlay';
import CategorySelector from '../widgets/category/CategorySelector';
import BarcodeWidget from '../widgets/shop/BarcodeWidget';
import SaveIcon from '@mui/icons-material/Save';
import IfTrue from '../conditions/IfTrue';
import { Snackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import DoubleClickTextField from '../widgets/inputs/DoubleClickTextField';
import ConfigContext from '../../contexts/ConfigContext';
import VariantEditor from './VariantEditor';
import ItemImage from '../widgets/shop/ItemImage';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AlertDialog from '../dialogs/AlertDialog';

type Props = {
    reloadPage?: boolean;
    setReloadPage?: React.Dispatch<React.SetStateAction<boolean>>;
    item: IItem;
    setOnSavePage: Dispatch<SetStateAction<() => void>>;
}

const ItemEditor = ({reloadPage, setReloadPage, item, setOnSavePage}: Props) => {
    const {config} = useContext(ConfigContext);
    const root = config.general.root_category;
    
    const [swerr, setSwerr] = React.useState<string>('');
    const [swsuccess, setSwsuccess] = React.useState<string>('');
    const history = useHistory();
    let [isBlocking, setIsBlocking] = useState(false);
    let [isDeleting, setIsDeleting] = useState(false);
    let [refreshParent, setRefreshParent] = useState(false);

    const [imageUrl, setImageUrl] = useState<string>(item.imageId ? 'https://thequeensflorist.sirv.com/' + root + '/' + item.imageId + '.jpg' : "");
    const {identity, setIdentity} = useContext(IdentityContext);
    const [saveChanges, setSaveChanges] = useState<boolean>(false);

    const location = useLocation();

    useEffect(() => {
    }, []);

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref,
      ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });

    useEffect(() => {
        if (setReloadPage) {
            setReloadPage(!reloadPage);
        }
        else {
            history.go(0);
        }
    }, [refreshParent, history, setReloadPage]);

    const toEditableItem = (item: IItem): IEditableItem => {
        const result = {
            id: item.id,
            name: item.name ? item.name : '',
            description: item.description ? item.description : '',
            categoryId: item.categoryId ? item.categoryId : '',
            brand: item.brand ? item.brand : '',
            price: item.price ? item.price : -1,
            cost: item.cost ? item.cost : -1,
            supplierUrls: item.supplierUrls && item.supplierUrls.length > 0 ? item.supplierUrls : [''],
            distributorUrl: item.distributorUrl ? item.distributorUrl : '',
            homeUrls: item.homeUrls,
            externalId: item.externalId,
            inventory: item.inventory ? JSON.parse(JSON.stringify(item.inventory)) : {},
            discontinued: item.discontinued ? item.discontinued : false,
            hidden: item.hidden ? item.hidden : false,
            special: item.special ? item.special : false,
            notes: item.notes ? item.notes : '',
            codes: {
                upc: item.codes?.upc,
                sku: item.codes?.sku
            },
            group: {
                enabled: item.group?.enabled ? item.group.enabled : false,
                groupId: item.group?.groupId ? item.group.groupId : '',
                variantName: item.group?.variantName ? item.group.variantName : '',
                imageUrl: item.group?.imageUrl ? item.group?.imageUrl : ''
            }
        }
        return result;
    }

    useEffect(() => {
        setOnSavePage(() => handleSave);
    }, []);


    useEffect(() => {
        setOverride(toEditableItem(item));
        setImageUrl(item.imageId ? 'https://thequeensflorist.sirv.com/' + root + '/' + item.imageId + '.jpg' : "");
    }, [item]);


    const [override, setOverride] = useState<IEditableItem>(toEditableItem(item));

    /**
     * When a save is requested, we want to do a bit of rendering and
     * THEN do the save, which is why we are using an effect.
     */
    useEffect(() => {
        const doSaveChanges = async () => {
            if (!saveChanges || !identity || !identity) {
                return;
            }
            console.log("Start save changes");
            let success = true;
            if (!item.codes) {
                item.codes = {};
            }
            try {
                if (item.brand !== override.brand) {
                    success = success && (await API.updateItemField(item.id, 'brand', override.brand, identity.accessToken));
                }
                if (item.name !== override.name) {
                    success = success && (await API.updateItemField(item.id, 'name', override.name, identity.accessToken));
                }
                if (item.description !== override.description) {
                    success = success && (await API.updateItemField(item.id, 'description', override.description, identity.accessToken));
                }
                if (item.categoryId !== override.categoryId) {
                    success = success && (await API.updateItemField(item.id, 'categoryId', override.categoryId, identity.accessToken));
                }
                const inventoryKeys = Object.keys(override.inventory);
                for (let i = 0; i < inventoryKeys.length; i++) {
                    const key = inventoryKeys[i];
                    if (!item.inventory[key] || item.inventory[key].count != override.inventory[key].count) {
                        success = success && (await API.updateItemField(item.id, 'inventory', override.inventory, identity.accessToken));
                        break;
                    }
                }
                if (item.cost !== override.cost) {
                    success = success && (await API.updateItemField(item.id, 'cost', override.cost, identity.accessToken));
                }
                if (item.codes.sku !== override.codes.sku) {
                    success = success && (await API.updateItemField(item.id, 'sku', override.codes.sku, identity.accessToken));
                }
                if (item.codes.upc !== override.codes.upc) {
                    success = success && (await API.updateItemField(item.id, 'upc', override.codes.upc, identity.accessToken));
                }
                if (item.price !== override.price) {
                    success = success && (await API.updateItemField(item.id, 'price', override.price, identity.accessToken));
                }
                if (item.notes !== override.notes) {
                    success = success && (await API.updateItemField(item.id, 'notes', override.notes, identity.accessToken));
                }
                if (item.hidden !== override.hidden) {
                    success = success && (await API.updateItemField(item.id, 'hidden', override.hidden, identity.accessToken));
                }
                if (item.special !== override.special) {
                    success = success && (await API.updateItemField(item.id, 'special', override.special, identity.accessToken));
                }
                if (item.discontinued !== override.discontinued) {
                    success = success && (await API.updateItemField(item.id, 'discontinued', override.discontinued, identity.accessToken));
                }


                // Group stuff
                if (item.group?.enabled !== override.group.enabled) {
                    success = success && (await API.updateItemField(item.id, 'group.enabled', override.group.enabled, identity.accessToken));
                }

                if (item.group?.groupId !== override.group.groupId) {
                    success = success && (await API.updateItemField(item.id, 'group.groupId', override.group.groupId, identity.accessToken));
                }

                if (item.group?.variantName !== override.group.variantName) {
                    success = success && (await API.updateItemField(item.id, 'group.variantName', override.group.variantName, identity.accessToken));
                }



                // Image stuff

                if (imageUrl && imageUrl.indexOf('thequeensflorist.sirv.') <= 0) {
                    const tokens = imageUrl.split('?');
                    success = success && (await API.cloneImage(item.id, tokens[0], identity.accessToken));
                }

                if (item.distributorUrl !== override.distributorUrl) {
                    success = success && (await API.updateItemField(item.id, 'distributorUrl', override.distributorUrl, identity.accessToken));
                }
                
                // Do this one last so that it updates only empty fields
                if (!item.supplierUrls) {
                    if (override.supplierUrls && override.supplierUrls.length > 0) {
                        // Create
                        success = success && (await API.updateItemField(item.id, 'supplierUrl', override.supplierUrls[0], identity.accessToken));
                    }
                }
                else {
                    if (override.supplierUrls && override.supplierUrls.length > 0) {
                        if (item.supplierUrls[0] !== override.supplierUrls[0]) {
                            // Replace
                            success = success && (await API.updateItemField(item.id, 'supplierUrl', override.supplierUrls[0], identity.accessToken));
                        }
                        // else nothing changed
                    }
                    else {
                        // Delete
                        success = success && (await API.updateItemField(item.id, 'supplierUrl', '', identity.accessToken));
                    }
                }

                if (success) {
                    await API.syncItem(item, identity.accessToken);
                }

                setIsBlocking(false);

                // This refreshes the page data
                setRefreshParent(!refreshParent);
                setSwsuccess("Success");
            }
            catch (err: any) {
                console.log("Unexpected error: " + err);
                if (err.message) {
                    setSwerr(err.message);
                }
            }
            setSaveChanges(false); // DO this so we don't get in a save loop
        }

        doSaveChanges();
    }, [saveChanges, item, identity]);

    /**
     * Don't pass this point if we are not in the editor group
     */
    if (!identity?.roles?.includes('editor')) {
        return (
            <>
            </>
        )
    }

    const handleEditSku = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOverride({
            ...override,
            codes: {
                ...override.codes,
                sku: event.target.value
            }
        });
        setIsBlocking(true);
    }
    
    const handleEditUpc = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOverride({
            ...override,
            codes: {
                ...override.codes,
                upc: event.target.value
            }
        });
        setIsBlocking(true);
    }

    const handleEditStock = (key: string, value: number) => {
        const inventory: InventoryItem = override.inventory[key] ? override.inventory[key] : {} as InventoryItem;
        inventory.count = value;

        const newOverride = {
            ...override
        }

        newOverride.inventory[key] = inventory;
        setOverride(newOverride);
        setIsBlocking(true);
    }

    const handleEditDistributor = (value: string) => {
        setOverride({
            ...override,
            distributorUrl: value
        });
        setIsBlocking(true);
    }

    const handleEditBrand = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOverride({
            ...override,
            brand: event.target.value
        });
        setIsBlocking(true);
    }
    
    const handleEditName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOverride({
            ...override,
            name: event.target.value
        });
        setIsBlocking(true);
    }
    
    const handleEditCost = (value: number) => {
        setOverride({
            ...override,
            cost: value
        });
        setIsBlocking(true);
    }
    
    const handleEditPrice = (value: number) => {
        setOverride({
            ...override,
            price: value
        });
        setIsBlocking(true);
    }

    const handleEditCategory = (categoryId?: string) => {
        if (!categoryId) {
            categoryId = '';
        }
        setOverride({
            ...override,
            categoryId: categoryId
        })
        setIsBlocking(true);
    }
    
    const handleEditSupplier = (value: string) => {
        setOverride({
            ...override,
            supplierUrls: [value]
        })
        setIsBlocking(true);
    }
    
    const handleEditImage = (value: string) => {
        if (value.indexOf('thequeensflorist.sirv.') <= 0) {
            setImageUrl(value);
        }
        setIsBlocking(true);
    }
    
    const handleEditDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOverride({
            ...override,
            description: event.target.value
        });
        setIsBlocking(true);
    }
    
    const handleEditNotes = (msg: string) => {
        setOverride({
            ...override,
            notes: msg
        });
        setIsBlocking(true);
    }

    const handleEditHidden = ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setOverride({
            ...override,
            hidden: checked
        });
        setIsBlocking(true);
    });
    
    const handleEditSpecial = ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setOverride({
            ...override,
            special: checked
        });
        setIsBlocking(true);
    });
    
    const handleEditDiscontinued = ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setOverride({
            ...override,
            discontinued: checked
        });
        setIsBlocking(true);
    });

    const handleEditGroupEnabled = ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setOverride({
            ...override,
            group: {
                ...override.group,
                enabled: checked
            }
        });
        setIsBlocking(true);
    });

    const handleEditGroupId = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOverride({
            ...override,
            group: {
                ...override.group,
                groupId: event.target.value
            }
        });
        setIsBlocking(true);
    }
    
    const handleEditVariantName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOverride({
            ...override,
            group: {
                ...override.group,
                variantName: event.target.value
            }
        });
        setIsBlocking(true);
    }
    
    const handleSave = () => {
        setSaveChanges(true);
        setIsBlocking(true);
    }

    const handleSwerrClose = (reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSwerr('');
    };

    const handleSwsuccessClose = (reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSwsuccess('');
    };

    const onDeleteItem = async () => {
        try {
            await API.deleteItem(item, identity.accessToken);
            if (location.state && (location.state as any).from) {
                history.goBack();
              }
              else {
                console.log(`history.push(/)`);
                history.push('/');
                history.go(0);
              }    
            }
        catch (e: any) {
            setSwerr(e.message);
        }
    }

    /**
     * We are an editor. Huzzah!
     */
    return (
        <>
        <Snackbar open={!!swerr} autoHideDuration={6000} onClose={(event?: any, reason?: string) => {handleSwerrClose(reason)}}>
          <Alert onClose={(event?: any, reason?: string) => {handleSwerrClose(reason)}} severity="error">
            Error: {swerr}
          </Alert>
        </Snackbar>
        <Snackbar open={!!swsuccess} autoHideDuration={6000} onClose={(event?: any, reason?: string) => {handleSwerrClose(reason)}}>
          <Alert onClose={(event?: any, reason?: string) => {handleSwsuccessClose(reason)}} severity="success">
            Update successful
          </Alert>
        </Snackbar>
        <AlertDialog
            initialState={isDeleting}
            title="Delete item"
            text={`Deleting "${item.name}"; this cannot be undone. Are you sure?`}
            onYes={onDeleteItem}
            onNo={() => {setIsDeleting(false);}}/>
        <ProgressOverlay enabled={saveChanges}>
            <div className='editor-cards'>
                <Card className={"item-edit"}>
                <CardActions>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    onClick={() =>
                    handleSave()}>
                    Apply Changes
                </Button>
                <Button
                    variant="outlined"
                    color="error"
                    startIcon={<DeleteForeverIcon />}
                    onClick={() => {setIsDeleting(true);}}>
                    Delete Item
                </Button>
                </CardActions>
                <CardContent>
                <form noValidate autoComplete="off"
                    onSubmit={event => {
                        event.preventDefault();
                        setIsBlocking(false);}}>
                    <Prompt
                        when={isBlocking}
                        message={location =>
                        `You have made changes to this item that have not been saved. Are you sure you want to go?`
                        }
                    />
                    <div>
                    Item editor
                    </div>
                    <div color="textSecondary">
                        ID: {item.id}
                    </div>
                    <div className='row'>
                        <Card className='group'>
                            <CardContent>
                                <ItemImage width={180} height={180} id={item.imageId} title={item.name} timestamp={item.updatedAt?.toString()}/>
                            </CardContent>
                        </Card>

                        <Card className='group'>
                            <CardContent>
                                <FormControlLabel
                                        control={
                                        <Checkbox
                                            checked={override.group.enabled}
                                            onChange={handleEditGroupEnabled}
                                            name="checkedGrouped"
                                        />
                                        }
                                        label="Group Item"
                                    />
                                <IfTrue condition={override.group.enabled}>
                                    <div className="item-editor-text-fields"><TextField InputLabelProps={{ shrink: true }} id="group-id" label="Group Item ID" value={override.group.groupId} onChange={handleEditGroupId}/></div>
                                    <div className="item-editor-text-fields"><TextField InputLabelProps={{ shrink: true }} id="variant-name" label="Variant Name" value={override.group.variantName} onChange={handleEditVariantName}/></div>
                                    {/* <div className="item-editor-text-fields"><TextField InputLabelProps={{ shrink: true }} id="edit-imageUrl" label="New Image URL" value={override.group.imageUrl}/></div> */}
                                </IfTrue>
                            </CardContent>
                        </Card>
                    </div>

                    <IfTrue condition={!override.group?.enabled || !!override.group?.variantName}>
                        <Card className='group editor-card'>
                            <CardHeader className='group-header' title="Identification"/>
                            <CardContent className='content'>
                                <TextField InputLabelProps={{ shrink: true }} id="edit-upc" label="UPC" value={override.codes.upc} onChange={handleEditUpc}/>
                                <TextField disabled inputProps={{ maxLength: 7 }} InputLabelProps={{ shrink: true }} id="edit-sku" label="SKU" value={override.codes.sku} onChange={handleEditSku}/>
                            </CardContent>
                        </Card>
                    </IfTrue>
                        
                    <div className='item-editor-fields'>
                        <div className="item-editor-text-fields"><TextField InputLabelProps={{ shrink: true }} id="edit-brand" label="Brand" value={override.brand} onChange={handleEditBrand}/></div>
                        <div className="item-editor-text-fields"><TextField InputLabelProps={{ shrink: true }} id="edit-name" label="Name" value={override.name} onChange={handleEditName}/></div>
                        <div className="item-editor-text-fields"><CategorySelector value={override.categoryId} onChange={handleEditCategory}/></div>
                    </div>

                    <IfTrue condition={!item.group?.enabled || !!item.group?.variantName}>
                        <Card className='group editor-card'>
                            <CardHeader className='group-header' title="Cost/Price"/>
                            <CardContent className='content'>
                                <PriceInput id="edit-cost" label="Cost" value={override.cost} onCommit={handleEditCost}/>
                                <PriceInput id="edit-price" label="Price" value={override.price} onCommit={handleEditPrice}/>
                            </CardContent>
                        </Card>

                        <Card id='stock-count' className='group editor-card'>
                            <CardHeader className='group-header' title="Stock Count" subheader="By location"/>
                            <CardContent className='content'>
                                <CountInput id="edit-stock" resetAt={override.inventory["liverpool"]?.resetAt} handleCommit={(value: number) => handleEditStock("liverpool", value)} label="Lvpl" initial={override.inventory["liverpool"] ? override.inventory["liverpool"].count : 0}/>
                                <CountInput id="edit-stock" resetAt={override.inventory["bridgewater"]?.resetAt} handleCommit={(value: number) => handleEditStock("bridgewater", value)} label="Bwtr" initial={override.inventory["bridgewater"] ? override.inventory["bridgewater"].count : 0}/>
                                <CountInput id="edit-stock" resetAt={override.inventory["warehouse"]?.resetAt} handleCommit={(value: number) => handleEditStock("warehouse", value)} label="Ware" initial={override.inventory["warehouse"] ? override.inventory["warehouse"].count : 0}/>
                                <CountInput id="edit-stock" resetAt={override.inventory["bongbus"]?.resetAt} handleCommit={(value: number) => handleEditStock("bongbus", value)} label="Bus" initial={override.inventory["bongbus"] ? override.inventory["bongbus"].count : 0}/>
                                <CountInput id="edit-stock" resetAt={override.inventory["overflow"]?.resetAt} handleCommit={(value: number) => handleEditStock("overflow", value)} label="Err" initial={override.inventory["overflow"] ? override.inventory["overflow"].count : 0}/>
                            </CardContent>
                        </Card>
                    </IfTrue>

                    <Card id='source-links' className='group editor-card'>
                        <CardHeader className='group-header' title="Links"/>
                        <CardContent>
                        <div className="item-editor-text-fields"><DoubleClickTextField id="edit-supplierUrl" label="Supplier Link" value={override.supplierUrls[0]} onCommit={handleEditSupplier}/></div>
                        <div className="item-editor-text-fields"><DoubleClickTextField id="edit-distributorUrl" label="Manufacturer Link" value={override.distributorUrl} onCommit={handleEditDistributor}/></div>
                        <div className="item-editor-text-fields"><DoubleClickTextField id="edit-imageUrl" label="Image URL (will be moved to our image server)" itemId={item.id} value={imageUrl} fileInput={true} onCommit={handleEditImage}/></div>
                        </CardContent>
                    </Card>

                    <div className='item-editor-fields'>
                        <div className="item-editor-text-fields">
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={override.hidden}
                                onChange={handleEditHidden}
                                name="checkedHidden"
                            />
                            }
                            label="Hidden"
                        />
                        </div>

                        <div className="item-editor-text-fields">
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={override.special}
                                onChange={handleEditSpecial}
                                name="checkedSpecial"
                            />
                            }
                            label="Special Order"
                        />
                        </div>

                        <div className="item-editor-text-fields">
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={override.discontinued}
                                onChange={handleEditDiscontinued}
                                name="checkedDiscontinued"
                            />
                            }
                            label="Discontinued"
                        />
                        </div>

                        
                    </div>
                    <div id="edit-description" className='edit-textarea'>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        label="Description"
                        multiline
                        rows={10}
                        value={override.description}
                        defaultValue={override.description}
                        onChange={handleEditDescription}
                        />
                    </div>
                    <div id="edit-notes" className='edit-textarea'>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        label="Notes"
                        multiline
                        rows={2}
                        value={override.notes}
                        defaultValue={override.notes}
                        onChange={(event) => {handleEditNotes(event.target.value)}}
                        onBlur={(event) => {handleEditNotes(event.target.value)}}
                        />
                    </div>
                </form>
                <div className='barcodes'>
                    <IfTrue condition={!item.group?.enabled || !!item.group?.variantName}>
                        <BarcodeWidget brand={override.brand} name={override.name} code={override.codes.upc} label='Download UPC' format='Ean13'/>
                        <BarcodeWidget brand={override.brand} name={override.name} code={override.codes.sku} label='Download SKU'/>
                    </IfTrue>
                </div>
                {/* <pre>
                    {JSON.stringify(override, null, 1)}
                </pre> */}
                </CardContent>
                <CardActions>
                <Button variant="contained" color="primary" onClick={() => handleSave()}>
                    Apply Changes
                </Button>
                </CardActions>
            </Card>
            <IfTrue condition={item.group?.enabled && !item.group?.groupId}>
                <Card>
                    <CardContent>
                    <div className='variants'>
                        <VariantEditor item={item}/>
                    </div>
                    </CardContent>
                </Card>
            </IfTrue>
        </div>
      </ProgressOverlay>
      </>
    )
}

export default ItemEditor
