import { IconButton, TextField } from '@mui/material';
import React, {useEffect, useLayoutEffect, useRef} from 'react';
import EditIcon from '@mui/icons-material/Edit';
import ReactMarkdown from 'react-markdown'

type Props = {
    value?: string,
    onCommit: (value: string) => void,
    readonly?: boolean,
    label?: string,
    id?: string,
    layout?: string
}

const EditableMarkdownField = ({ value, onCommit, readonly, label, id, layout }: Props) => {
    const [editing, setEditing] = React.useState<boolean>(false);
    const [textValue, setTextValue] = React.useState<string>(value ? value : '');

    const searchInput = useRef<HTMLInputElement>(null)

    useEffect(() => {
        setTextValue(value ? value : '');
    }, [value]);

    useLayoutEffect(() => {
        if (searchInput?.current) {
            searchInput.current.focus();
        }
    });
      
    const onChange = (event: React.FocusEvent<HTMLInputElement>) => {
        const stringValue = event.target.value;
        setTextValue(stringValue);
    }

    const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const stringValue = event.target.value.trim();
        setEditing(false);
        setTextValue(stringValue);        
        onCommit(stringValue);
    }

    const onClick = () => {
        setEditing(true);
    }

    if (editing) {
        return (
        <div className='markdown-field'>
            <TextField
                inputRef={searchInput}
                className='dctf-textfield'
                InputLabelProps={{ shrink: true }}
                multiline
                id={id}
                label={label}
                value={textValue}
                onChange={onChange}
                onBlur={onBlur}/>
        </div>
        );
    }

    return (
        <div className='markdown-field'>
            <div className='dctf-label'>
                {label}
            </div>
            <div className='dctf-text'>
                <IconButton aria-label="Edit" size="small" onClick={onClick}>
                    <EditIcon fontSize="small" />
                </IconButton>
                <ReactMarkdown>{textValue}</ReactMarkdown>
            </div>
        </div>
    )
}

export default EditableMarkdownField