import React, { useEffect, useState, useContext } from 'react';
import API from '../../API';
import IdentityContext from '../auth/IdentityContext';
import { Card, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import SearchField from '../widgets/quickbar/SearchField';
import ProgressOverlay from '../overlays/ProgressOverlay';
import InventoryItemEditor from '../widgets/InventoryItemEditor';


const InventoryPage = () => {
  const [items, setItems] = useState<IItem[]>([]);
  const {identity, setIdentity} = useContext(IdentityContext);
  const [loading, setLoading] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>()

  useEffect(() => {
    const doUpdateSearch = async() => {
      if (searchText) {
        setLoading(true);
        console.log("SEARCH: " + searchText);
        const items = await API.searchItems(searchText, identity?.accessToken);
        setLoading(false);
        setItems(items);  
      }
    }

    doUpdateSearch()
  }, [identity?.accessToken, searchText]);

  const handleSearch = async (searchText: string) => {
    setSearchText(decodeURIComponent(searchText));
  }


  return (
    <ProgressOverlay enabled={loading}>
      <div className="inventory-page">
        <Card>
          <h1>Quick Inventory</h1>
          <SearchField display='desktop' onSearch={handleSearch} defaultSearch={searchText}/>
        </Card>
        <Card>
          <Table size="small" aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>UPC/SKU</TableCell>
                <TableCell>Cost</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Stock</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {items.map((item: IItem) => (
              <InventoryItemEditor item={item}/>
            ))}
            </TableBody>
          </Table>
        </Card>
      </div>
    </ProgressOverlay>
  )
}

export default InventoryPage