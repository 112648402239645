import React, { useContext, useState } from 'react';
import { Link } from "react-router-dom";
import CategoryChildPanel from './CategoryChildPanel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useDrag, useDrop } from 'react-dnd'
import { DnDTypes } from "../dnd/DnDTypes";
import IdentityContext from '../../auth/IdentityContext';
import IfAuthorized from '../../conditions/IfAuthorized';
import IconButton from '@mui/material/IconButton';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForever from '@mui/icons-material/DeleteForever'
import TextEntryDialog from '../../dialogs/TextEntryDialog';
import API from '../../../API';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import AlertDialog from '../../dialogs/AlertDialog';
import CategoryContext from '../../../contexts/CategoryContext';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type Props = {
  group?: string
  selectedCategory?: string
  category: ICategory
}

const CategoryWidget = ({ category, group, selectedCategory }: Props) => {
  const {identity, setIdentity} = useContext(IdentityContext);
  const [addingCategory, setAddingCategory] = useState<boolean>(false);
  const [renamingCategory, setRenamingCategory] = useState<boolean>(false);
  const [deleteCategoryToggle, setDeleteCategoryToggle] = useState<boolean>(false);
  const [open, setOpen] = React.useState<string>('');
  const { doCategoryRefresh } = useContext(CategoryContext);

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen('');
  };

  const [{ isDragging }, drag] = useDrag(() => ({
    type: DnDTypes.CATEGORY,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    end: async (droppedItem, monitor) => {
      const dropResult = monitor.getDropResult<ICategory>()
      if (category && dropResult) {
        if (identity?.roles?.includes('editor')) {
          // setMovingItem(dropResult);
          console.log('MOVE: ' + category.shortName + ' to ' + JSON.stringify(dropResult));
          try {
            await API.moveCategory(category.id, ""+dropResult.id, identity.accessToken);
            doCategoryRefresh();
          }
          catch (err: any) {
            setOpen(err.message);
          }
        }
      }
    },
  }));

  const [{ canDrop: canDropCategory, isOver: isCategoryOver }, drop] = useDrop(() => ({
      accept: DnDTypes.CATEGORY,
      drop: () => (category),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop() && identity?.roles?.includes('editor'),
      }),
    })
  );

  const [{ canDropItem, isItemOver }, dropItem] = useDrop(() => ({
      accept: DnDTypes.ITEM,
      drop: () => (category),
      collect: (monitor) => ({
        isItemOver: monitor.isOver(),
        canDropItem: monitor.canDrop() && identity?.roles?.includes('editor'),
      }),
    })
  );

  const opacity = isDragging ? 0.4 : 1
  const isActive = (canDropCategory && isCategoryOver) || (canDropItem && isItemOver);
  let backgroundColor = isActive ? 'lightGrey' : 'transparent';

  // Can't drop on yourself, or if we are not authorized
  const dropRef = !isDragging && identity?.roles?.includes('editor') ? drop : undefined;
  
  const addCategory = async (name: string): Promise<void> => {
    if (name) {
      console.log('Adding category: ' + name);
      const newCategory: ICategory = await API.createCategory(category.shortName, name, identity?.accessToken);
      console.log('  * ' + JSON.stringify(newCategory));
      doCategoryRefresh();
    }
    setAddingCategory(false);
  }

  const doRenameCategory = async (name: string): Promise<void> => {
    if (name) {
      console.log('Renaming category as: ' + name);
      const newCategory: ICategory = await API.renameCategory(category.id, name, identity?.accessToken);
      console.log('  * ' + JSON.stringify(newCategory));
      doCategoryRefresh();
    }
    setRenamingCategory(false);
  }

  const doDelete = async (id: string): Promise<void> => {
    console.log("Delete category: " + id);
    try {
      await API.deleteCategory(id, identity?.accessToken);
      doCategoryRefresh();
    }
    catch (err: any) {
      setOpen(err.message);
    }
  }

  const moveCategoryUp = async (id: string): Promise<void> => {
    console.log("Move up: " + id);
    try {
      await API.reorderCategory(id, -1, identity?.accessToken);
      doCategoryRefresh();
    }
    catch (err: any) {
      setOpen(err.message);
    }
  }

  const moveCategoryDown = async (id: string): Promise<void> => {
    console.log("Move down: " + id);
    try {
      await API.reorderCategory(id, 1, identity?.accessToken);
      doCategoryRefresh();
    }
    catch (err: any) {
      setOpen(err.message);
    }
  }

  // Clicked on a group
  if (category.children.length > 0) {
    const url = "/category/" + category.shortName;
    return <>
      <Snackbar open={!!open} autoHideDuration={6000} onClose={(event?: any, reason?: string) => {handleClose(reason)}}>
        <Alert onClose={(event?: any, reason?: string) => {handleClose(reason)}} severity="error">
          Error: {open}
        </Alert>
      </Snackbar>
      <TextEntryDialog
        initialState={addingCategory}
        title="Create new category"
        text={`Add subcategory under '${category.shortName}'`}
        prompt="New category name"
        onCancel={() => setAddingCategory(false)}
        onOK={(value: string) => {addCategory(value);}}
        />
      <TextEntryDialog
        initialState={renamingCategory}
        title="Rename category"
        text={`Rename category '${category.shortName}' as`}
        prompt="New category name"
        onCancel={() => setRenamingCategory(false)}
        onOK={(value: string) => {doRenameCategory(value);}}
        />
      <AlertDialog
        initialState={deleteCategoryToggle}
        title="Delete category"
        text={`Delete ${category.shortName}?`}
        onYes={() =>{doDelete(category.id)}}
        onNo={() => {setDeleteCategoryToggle(false);}}/>
      <div ref={dropRef}><div ref={dropItem}>
        <div ref={drag} style={{backgroundColor}} className="category-widget">
          <Link to={url}>{category.shortName}</Link>
          <IfAuthorized role='editor'>
            <IconButton
              className='tree-icon'
              aria-label="rename category"
              onClick={() => setRenamingCategory(true)}
              size="large">
              <EditIcon />
            </IconButton>
            <IconButton
              className='tree-icon'
              aria-label="create category"
              onClick={() => setAddingCategory(true)}
              size="large">
              <CreateNewFolderIcon />
            </IconButton>
            <IconButton
              className='tree-icon'
              aria-label="delete category"
              onClick={() => setDeleteCategoryToggle(true)}
              size="large">
              <DeleteForever />
            </IconButton>
            <IconButton
              className='tree-icon'
              aria-label="move up"
              onClick={() => moveCategoryUp(category.id)}
              size="large">
              <KeyboardArrowUpIcon />
            </IconButton>
            <IconButton
              className='tree-icon'
              aria-label="move up"
              onClick={() => moveCategoryDown(category.id)}
              size="large">
              <KeyboardArrowDownIcon />
            </IconButton>
          </IfAuthorized>
        </div>
      </div></div>
      <CategoryChildPanel children={category.children} group={group} selectedCategory={selectedCategory}/>
    </>;
  }

  // Clicked on a category
  const url = "/category/" + (group ? group + '/' : '') + category.id;
  
  if (selectedCategory === category.id) {
    return <>
      <Snackbar open={!!open} autoHideDuration={6000} onClose={(event?: any, reason?: string) => {handleClose(reason)}}>
        <Alert onClose={(event?: any, reason?: string) => {handleClose(reason)}} severity="error">
          Error: {open}
        </Alert>
      </Snackbar>
      <TextEntryDialog
        initialState={addingCategory}
        title="Create new category"
        text={`Add subcategory under '${category.shortName}'`}
        prompt="New category name"
        onCancel={() => setAddingCategory(false)}
        onOK={(value: string) => {addCategory(value);}}
        />
      <TextEntryDialog
        initialState={renamingCategory}
        title="Rename category"
        text={`Rename category '${category.shortName}' as`}
        prompt="New category name"
        onCancel={() => setRenamingCategory(false)}
        onOK={(value: string) => {doRenameCategory(value);}}
        />
      <AlertDialog
        initialState={deleteCategoryToggle}
        title="Delete category"
        text={`Delete ${category.shortName}?`}
        onYes={() =>{doDelete(category.id)}}
        onNo={() => {setDeleteCategoryToggle(false);}}/>
      <div ref={dropRef}><div ref={dropItem}>
        <div ref={drag} style={{ opacity, backgroundColor }} className="category-widget selected-category"><FontAwesomeIcon icon={faChevronRight} title={category.shortName} />
          &nbsp;{category.shortName}
          <IfAuthorized role='editor'>
            <IconButton
              className='tree-icon'
              aria-label="create category"
              onClick={() => setRenamingCategory(true)}
              size="large">
              <EditIcon />
            </IconButton>
            <IconButton
              className='tree-icon'
              aria-label="create category"
              onClick={() => setAddingCategory(true)}
              size="large">
              <CreateNewFolderIcon />
            </IconButton>
            <IconButton
              className='tree-icon'
              aria-label="delete category"
              onClick={() => setDeleteCategoryToggle(true)}
              size="large">
              <DeleteForever />
            </IconButton>
            <IconButton
              className='tree-icon'
              aria-label="move up"
              onClick={() => moveCategoryUp(category.id)}
              size="large">
              <KeyboardArrowUpIcon />
            </IconButton>
            <IconButton
              className='tree-icon'
              aria-label="move up"
              onClick={() => moveCategoryDown(category.id)}
              size="large">
              <KeyboardArrowDownIcon />
            </IconButton>
          </IfAuthorized>
        </div>
      </div></div>
    </>;
  }

  return <>
      <Snackbar open={!!open} autoHideDuration={6000} onClose={(event?: any, reason?: string) => {handleClose(reason)}}>
        <Alert onClose={(event?: any, reason?: string) => {handleClose(reason)}} severity="error">
          Error: {open}
        </Alert>
      </Snackbar>
      <TextEntryDialog
        initialState={addingCategory}
        title="Create new category"
        text={`Add subcategory under '${category.shortName}'`}
        prompt="New category name"
        onCancel={() => setAddingCategory(false)}
        onOK={(value: string) => {addCategory(value);}}
        />
      <TextEntryDialog
        initialState={renamingCategory}
        title="Rename category"
        text={`Rename category '${category.shortName}' as`}
        prompt="New category name"
        onCancel={() => setRenamingCategory(false)}
        onOK={(value: string) => {doRenameCategory(value);}}
        />
      <AlertDialog
        initialState={deleteCategoryToggle}
        title="Delete category"
        text={`Delete ${category.shortName}?`}
        onYes={() =>{doDelete(category.id)}}
        onNo={() => {setDeleteCategoryToggle(false);}}/>
    <div ref={dropRef}><div ref={dropItem}>
      <div ref={drag} style={{ opacity, backgroundColor }}  className="category-widget">
        <Link to={url}>{category.shortName}</Link>
        <IfAuthorized role='editor'>
          <IconButton
            className='tree-icon'
            aria-label="create category"
            onClick={() => setRenamingCategory(true)}
            size="large">
            <EditIcon />
          </IconButton>
          <IconButton
            className='tree-icon'
            aria-label="create category"
            onClick={() => setAddingCategory(true)}
            size="large">
            <CreateNewFolderIcon />
          </IconButton>
          <IconButton
            className='tree-icon'
            aria-label="delete category"
            onClick={() => setDeleteCategoryToggle(true)}
            size="large">
            <DeleteForever />
          </IconButton>
          <IconButton
            className='tree-icon'
            aria-label="move up"
            onClick={() => moveCategoryUp(category.id)}
            size="large">
            <KeyboardArrowUpIcon />
          </IconButton>
          <IconButton
            className='tree-icon'
            aria-label="move up"
            onClick={() => moveCategoryDown(category.id)}
            size="large">
            <KeyboardArrowDownIcon />
          </IconButton>
        </IfAuthorized>
      </div>
    </div></div>
  </>;
}

export default CategoryWidget
