import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IfAuthorized from '../../conditions/IfAuthorized';

type Props = {
  state?: string,
  handleStateChange?: (event: SelectChangeEvent<{ value: unknown }>) => void
}

const ReviewState = ({ state, handleStateChange }: Props) => {

  const myState = state ? state : "NEW";

  const states: string[] = [];
  switch (myState) {
    case 'NEW':
      states.push("NEW");
      states.push("OPEN");
      states.push("CLOSED");
      break;
    case 'OPEN':
      states.push("OPEN");
      states.push("CANCELLED");
      states.push("PAID");
      break;
    case 'CANCELLED':
      states.push("CANCELLED");
      break;
    case 'PAID':
      states.push("PAID");
      states.push("SHIPPED");
      states.push("REFUNDED");
      break;
    case 'REFUNDED':
      states.push("REFUNDED");
      break;
    case 'SHIPPED':
      states.push("SHIPPED");
      states.push("DELIVERED");
      states.push("REFUNDED");
      break;
    case 'DELIVERED':
      states.push("DELIVERED");
      states.push("CLOSED");
      states.push("REFUNDED");
      break;
    case 'CLOSED':
      states.push("CLOSED");
      states.push("REFUNDED");
      break;
      }

  return (
    <IfAuthorized role='editor'>
      <div>State:
        <Select
          value={{value: myState}}
          onChange={handleStateChange}
          displayEmpty
        >
          {states.map((state: string) => (
            <MenuItem value={state} disabled={state === myState}>{state}</MenuItem>
          ))}
        </Select>
      </div>
    </IfAuthorized>
  )
}

export default ReviewState