import { Card, CardContent } from '@mui/material';

type Props = {
  order: IOrder
}


const InvoiceReviewDelivery = ({ order }: Props) => {
  if (order.deliveryDate) {
    const date = new Date(order.deliveryDate);
    const month = date.toLocaleString('default', { month: 'long' });
    const day = date.toLocaleString('en-us', {  weekday: 'long' });
    const deliveryString = `${day} ${month} ${date.getDate()}, ${date.getFullYear()}`;

    return (
      <Card>
        <CardContent>
          Delivery Date: {deliveryString}
        </CardContent>
      </Card>
    )
  }

  return (<></>);
}

export default InvoiceReviewDelivery