import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../API';
import Item from './Item'
import Grid from '@mui/material/Grid';
import IdentityContext from '../../auth/IdentityContext';
import ProgressOverlay from '../../overlays/ProgressOverlay';
import SubcategoryPanel from './SubcategoryPanel';
import BreadCrumbs from '../BreadCrumbs';

const Shop = () => {
  let { categoryId } = useParams<{categoryId: string}>();
  let { searchText } = useParams<{searchText: string}>();
  const {identity, setIdentity} = useContext(IdentityContext);

  const [loading, setLoading] = useState<boolean>(false)
  const [items, setItems] = useState<IItem[]>([])
  const [currentCategoryId, setCurrentCategoryId] = useState<string|undefined>(undefined);
  let { itemId } = useParams<{itemId: string}>();

  // We only want to refresh the item data in certain circumstances
  const refreshRequired = !itemId && (
    !currentCategoryId ||
    ((currentCategoryId !== categoryId) && (currentCategoryId !== searchText)));

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      try {
        if (searchText) {
          const items = await API.searchAllItems(searchText, identity?.accessToken);
          setItems(items);  
          setCurrentCategoryId(searchText);
        }
        else {
          const items = await API.getItemsByCategory(categoryId, identity?.accessToken);
          // FIXME: We're going to want to allow selectable sorts here!
          items.sort((i1, i2) => {
            const s1 = Object.keys(i1.inventory).reduce((sum, key) => sum + i1.inventory[key].count, 0);
            const s2 = Object.keys(i2.inventory).reduce((sum, key) => sum + i2.inventory[key].count, 0);
            
            if (s1 > 0 && s2 <= 0) {
                return -1;
            }
            if (s2 > 0 && s1 <= 0) {
                return 1;
            }
            return i1.name.localeCompare(i2.name);
          });

          setItems(items);  
          setCurrentCategoryId(categoryId);
        }
      }
      catch (err: any) {
        console.error(err.message);
      }
      setLoading(false);
    }

    if (refreshRequired) {
      fetchItems();
    }
  }, [identity, searchText, categoryId]);

  if (!loading && items.length === 0) {
    return (
      <>
        <div className='infobar'>
          <BreadCrumbs categoryId={categoryId}/>
        </div>
        <SubcategoryPanel categoryId={categoryId}/>
      </>
    )
  }  

  return <>
    {/* <Brands items={items}/> */}

    <ProgressOverlay enabled={loading}>
      <div className='infobar'>
        <BreadCrumbs categoryId={categoryId}/>
      </div>
      <Grid container justifyContent="center" spacing={2} className="items">
        {items.map((item: IItem) => (
          <Grid key={item.id} item>
            <Item
              key={item.id}
              item = {item}
            />
          </Grid>
        ))}

      </Grid>
    </ProgressOverlay>
  </>;
}

export default Shop