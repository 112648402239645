import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Link, Prompt } from 'react-router-dom';
import IdentityContext from '../auth/IdentityContext';
import { IconButton, Snackbar, TableBody } from '@mui/material';

import DoubleClickTextField from '../widgets/inputs/DoubleClickTextField';
import EditablePriceField from '../widgets/inputs/EditablePriceField';

import ConfigContext from '../../contexts/ConfigContext';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ItemImage from '../widgets/shop/ItemImage';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import API from '../../API';
import CategorySelector from '../widgets/category/CategorySelector';

type Props = {
    variant: IItem;
    categoryEditor?: boolean;
}

const SimpleItemRow = ({variant, categoryEditor}: Props) => {

    const [open, setOpen] = React.useState(false);
    const [menuOpen, setMenuOpen] = React.useState(false);
    const {config} = useContext(ConfigContext);
    const root = config.general.root_category;

    const {identity, setIdentity} = useContext(IdentityContext);

    const toEditableItem = (item: IItem): IEditableItem => {
        return {
            id: item.id,
            name: item.name ? item.name : '',
            description: item.description ? item.description : '',
            categoryId: item.categoryId ? item.categoryId : '',
            brand: item.brand ? item.brand : '',
            price: item.price ? item.price : -1,
            cost: item.cost ? item.cost : -1,
            supplierUrls: item.supplierUrls,
            distributorUrl: item.distributorUrl ? item.distributorUrl : '',
            homeUrls: item.homeUrls,
            externalId: item.externalId,
            inventory: item.inventory ? JSON.parse(JSON.stringify(item.inventory)) : {},
            discontinued: item.discontinued ? item.discontinued : false,
            hidden: item.hidden ? item.hidden : false,
            special: item.special ? item.special : false,
            notes: item.notes ? item.notes : '',
            codes: {
                upc: item.codes?.upc,
                sku: item.codes?.sku
            },
            group: {
                enabled: item.group?.enabled ? item.group.enabled : false,
                groupId: item.group?.groupId ? item.group.groupId : '',
                variantName: item.group?.variantName ? item.group.variantName : '',
                imageUrl: item.group?.imageUrl ? item.group?.imageUrl : ''
            }
        }
    }

    const [override, setOverride] = useState<IEditableItem>(toEditableItem(variant));

    const handleEditSku = async (value: string) => {
        setOverride({
            ...override,
            codes: {
                ...override.codes,
                sku: value
            }
        });
        await API.updateItemField(variant.id, 'sku', value, identity?.accessToken);
    }
    
    const handleEditUpc = async (value: string) => {
        setOverride({
            ...override,
            codes: {
                ...override.codes,
                upc: value
            }
        });
        await API.updateItemField(variant.id, 'upc', value, identity?.accessToken);
    }

    const handleEditVariantName = async (value: string) => {
        const newName = value;
        setOverride({
            ...override,
            name: newName
        });
        try {
            await API.updateItemField(variant.id, 'name', newName, identity?.accessToken);
        }
        catch (err: any) {
            console.error(err);
        }
    }

    const handleEditCost = async (value: number) => {
        console.log(value);
        setOverride({
            ...override,
            cost: value
        });
        await API.updateItemField(variant.id, 'cost', value, identity?.accessToken);
    }

    const handleEditPrice = async (value: number) => {
        console.log(value);
        setOverride({
            ...override,
            price: value
        });
        await API.updateItemField(variant.id, 'price', value, identity?.accessToken);
    }

    const handleEditCategory = async (categoryId?: string) => {
        if (!categoryId) {
            categoryId = '';
        }
        setOverride({
            ...override,
            categoryId: categoryId
        })
        await API.updateItemField(variant.id, 'categoryId', categoryId, identity?.accessToken);
    }


    /**
     * We are an editor. Huzzah!
     */
    if (categoryEditor) {
        return (
        <React.Fragment>
            <TableRow>
                <TableCell>
                    <a href={`/item/${variant.id}`} target="_blank" rel="noreferrer noopener">
                        <ItemImage className='thumbnail' id={variant.imageId} title={override.name} timestamp={variant.updatedAt?.toString()}/>
                    </a>
                </TableCell>
                <TableCell>
                    <DoubleClickTextField label="Variant Name" value={override.name} onCommit={handleEditVariantName}/>
                </TableCell>
                <TableCell>
                    <CategorySelector value={override.categoryId} onChange={handleEditCategory}/>
                </TableCell>

                <TableCell>
                <IconButton
                    aria-label="menu"
                    size="small"
                    onClick={() => setMenuOpen(true)}>
                        <MoreVertIcon/>
                </IconButton>
                </TableCell>
            </TableRow>

        </React.Fragment>
        )
    }
    else {
        return (
            <React.Fragment>
                <TableRow>
                    <TableCell>
                        <a href={`/item/${variant.id}`} target="_blank" rel="noreferrer noopener">
                            <ItemImage className='thumbnail' id={variant.imageId} title={override.name} timestamp={variant.updatedAt?.toString()}/>
                        </a>
                    </TableCell>
                    <TableCell>
                        <DoubleClickTextField label="Variant Name" value={override.name} onCommit={handleEditVariantName}/>
                    </TableCell>
                    <TableCell>
                        <div><EditablePriceField onCommit={handleEditPrice} label="Price" value={override.price}/></div>
                        <div><EditablePriceField onCommit={handleEditCost} label="Cost" value={override.cost}/></div>
                    </TableCell>
                    <TableCell>
                        <DoubleClickTextField label="UPC" value={override.codes?.upc} onCommit={handleEditUpc}/>
                        <DoubleClickTextField label="SKU" value={override.codes?.sku} onCommit={handleEditSku}/>
                    </TableCell>
                    <TableCell>
                    <IconButton
                        aria-label="menu"
                        size="small"
                        onClick={() => setMenuOpen(true)}>
                            <MoreVertIcon/>
                    </IconButton>
                    </TableCell>
                </TableRow>
    
            </React.Fragment>
        )
    }
}

export default SimpleItemRow
