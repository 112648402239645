
// import React from 'react';

const About = () => {
  return (
    <div id="main">
        <div>Font made from <a href="http://www.onlinewebfonts.com">Online Web Fonts</a>is licensed by CC BY 3.0</div>
        <div>No Image Available image from <a href="https://commons.wikimedia.org/wiki/File:No-image-available.png">Wikimedia</a>is licensed by CC SA 4.0</div>
    </div>
  )
}

export default About


