import { LogLevel } from "@azure/msal-browser";

// https://thequeensflorist.b2clogin.com/thequeensflorist.onmicrosoft.com/oauth2/v2.0/authorize?
// p=B2C_1_signupsignin1&
// client_id=0c9a5cf7-ce60-4b96-9aac-2b75f14e48cc&
// nonce=defaultNonce&
// redirect_uri=https%3A%2F%2Fjwt.ms&scope=openid&response_type=id_token&prompt=login

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID as string,
        authority: process.env.REACT_APP_MSAL_AUTHORITY as string,
        knownAuthorities: [
            process.env.REACT_APP_MSAL_KNOWN_AUTHORITY as string
          ],
        redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI as string,
        postLogoutRedirectUri: process.env.REACT_APP_MSAL_POST_LOGOUT_REDIRECT_URI as string,
        navigateToLoginRequestUrl: (process.env.REACT_APP_MSAL_NAVIGATE_TO_LOGIN_REQUEST_URL === 'true'),
    },
    cache: {
        cacheLocation: 'localStorage', //process.env.REACT_APP_MSAL_CACHE_LOCATION as string, // This configures where your cache will be stored
        storeAuthStateInCookie: (process.env.REACT_APP_MSAL_STORE_AUTH_STATE_IN_COOKIE === 'true'), // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
            piiLoggingEnabled: false
        },
        windowHashTimeout: 60000,
        iframeHashTimeout: 6000,
        loadFrameTimeout: 0
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["https://thequeensflorist.onmicrosoft.com/0c9a5cf7-ce60-4b96-9aac-2b75f14e48cc/read"]
};
