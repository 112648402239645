import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ItemImage from '../shop/ItemImage';
import Price from '../shop/Price';
import InvoicedItemLink from './InvoicedItemLink';

type Props = {
  item: ICartItem
}

const InvoicedItem = ({ item }: Props) => {
  console.log("WIKKID");
  return (
    <TableRow className='items-table-row' key={item.item.id}>
      <TableCell className='table-icons items-table-cell'>
      </TableCell>
      <TableCell className='noPadding items-table-cell items-image-cell'>
        <ItemImage width={80} height={80} id={item.item.imageId} title={item.item.name}/>
      </TableCell>
      <TableCell className='items-table-cell' component="th" scope="row">
        <InvoicedItemLink id={item.item.id} name={item.item.name}/>
        <div><Price price={item.item.price ? item.item.price : 0}/> ea.</div>
      </TableCell>
      <TableCell className='items-table-cell items-table-cell-quantity'>
        {item.count}
      </TableCell>
      <TableCell className='items-table-cell' align="right"><Price price={(item.item.price ? item.item.price : 0) * item.count}/></TableCell>
    </TableRow>
  );
}

export default InvoicedItem